import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import './Image.scss';

const defaultProps = {
    caption: '',
    className: '',
    image: {},
};

const propTypes = {
    caption: PropTypes.string,
    className: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
};

function Image({
    caption,
    className,
    image,
}) {
    const supportsPicture = typeof window !== 'undefined' ? window.HTMLPictureElement : null;

    return (
        <>
            <div className={`a-image ${className}`}>
                <div className="l-grid">
                    <figure className="a-image__container">
                        {image?.url && (
                            <>
                                {supportsPicture && (
                                    <Img
                                        alt={(image?.alt) ? image.alt : ''}
                                        className="a-image__image"
                                        fluid={{
                                            aspectRatio: image.dimensions.width / image.dimensions.height,
                                            src: `${image?.url}&w=50`,
                                            srcSet: `${image?.url}&w=375 375w, ${image?.url}&w=480 480w, ${image?.url}&w=768 768w, ${image?.url}&w=960 960w, ${image?.url}&w=1280 1280w`,
                                            sizes: '(max-width: 1000px) 100vw, 1000px',
                                        }}
                                    />
                                )}
                                {!supportsPicture && (
                                    <img alt={(image?.alt) ? image.alt : ''} className="a-image__image" src={`${image?.url}&w=1280`} />
                                )}
                            </>
                        )}
                        {caption && (
                            <figcaption className="a-image__caption">{caption}</figcaption>
                        )}
                    </figure>
                </div>
            </div>
        </>
    );
}

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
