import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import { ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slider, Slide, } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useNetworkState } from 'react-use';
import { Masthead } from 'molecules';

import './MastheadCarousel.scss';

const defaultProps = {
    className: '',
    image: {},
    listing: [],
    video: {},
    videoAutoplay: true,
};

const propTypes = {
    backgroundImage: PropTypes.objectOf(PropTypes.string),
    className: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    listing: PropTypes.arrayOf(PropTypes.object),
    video: PropTypes.object,
    videoAutoplay: PropTypes.bool,
};

function MastheadCarousel({
    className,
    image,
    listing,
    video,
    videoAutoplay,
}) {
    let supportsPicture = true;
    if (typeof window !== 'undefined') {
        supportsPicture = window?.HTMLPictureElement ? true : null;
    }
    const [videoIsPlaying, setVideoIsPlaying] = useState(true);
    const playerRef = useRef();
    const [ref, inView, entry] = useInView({
        threshold: 0,
    });
    videoAutoplay = (entry && !inView) ? false : true; // Pause the video when it’s no longer in view

    const [loadVideo, setLoadVideo] = useState(false);
    const supportsConnection = typeof window !== 'undefined' ? navigator.connection || navigator.mozConnection || navigator.webkitConnection : null;
    const networkState = supportsConnection ? useNetworkState() : '';
    // Only try and render the video if it hasn’t already been loaded or it’s not at build time
    if (!loadVideo && typeof window !== 'undefined') {
        // If the browser supports network detection and the network speed is fast enough, load the video or if it doesn’t support network detection, load the video
        if (networkState) {
            if (networkState.effectiveType === '3g' || networkState.effectiveType === '4g') {
                setLoadVideo(true);
            }
        } else {
            setLoadVideo(true);
        }
    }

    // Start autoplaying the carousel when the master background video has loaded and has started playing
    function playVideo() {
        setVideoIsPlaying(true);
    }

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.addEventListener('playing', playVideo);
        }

        return () => {
            if (playerRef.current) {
                playerRef.current.removeEventListener('playing', playVideo);
            }
        }
    }, [playerRef.current]);

    return (
        <>
            <div className={`o-masthead-carousel ${className}`} ref={ref}>
                <CarouselProvider className="o-masthead-carousel__container" dragEnabled={false} interval={8000} isPlaying={videoIsPlaying} naturalSlideHeight={125} naturalSlideWidth={100} totalSlides={listing.length} touchEnabled={false}>
                    <Slider className="o-masthead-carousel__slide-list">
                        {listing.map(({ cta_button_text, cta_button_url, cta_colour, cta_title, background_image, background_imageSharp, background_video, description, is_feefo_review, review_text, review_title, strapline, title }, index) => (
                            <Slide className="o-masthead-carousel__slide-item" classNameHidden="is-hidden" classNameVisible="is-visible" index={index} key={`{${index}${Math.random()}}`}>
                                <Masthead
                                    className="m-masthead--tall o-masthead-carousel__item"
                                    ctaButtonText={cta_button_text?.text}
                                    ctaButtonUrl={cta_button_url}
                                    ctaColour={cta_colour}
                                    ctaTitle={cta_title?.raw}
                                    description={description?.text}
                                    headingSize={(index === 0) ? 'h1' : 'h2'}
                                    image={(image?.url || video?.url) ? null : background_image}
                                    isFeefoReview={is_feefo_review}
                                    reviewText={review_text?.raw}
                                    reviewTitle={review_title?.raw}
                                    strapline={strapline?.text}
                                    title={title?.text}
                                    video={(image?.fluid || video?.url) ? null : background_video}
                                    videoAutoplay={(image?.fluid || video?.url) ? null : videoAutoplay}
                                />
                            </Slide>
                        ))}
                    </Slider>
                    <ButtonBack className="o-masthead-carousel__pagination-btn o-masthead-carousel__pagination-btn--prev">
                        <svg className="a-icon a-icon--fill o-masthead-carousel__btn-icon" viewBox="0 0 16 16">
                            <path d="M8 16A8 8 0 108-.001 8 8 0 008 16zM4.661 7.386L8.5 3.548A.872.872 0 019.73 4.783L6.517 7.997l3.213 3.216a.87.87 0 01-.258 1.461.874.874 0 01-.973-.23L4.66 8.608a.862.862 0 010-1.221z" />
                        </svg>
                    </ButtonBack>
                    <ButtonNext className="o-masthead-carousel__pagination-btn o-masthead-carousel__pagination-btn--next">
                        <svg className="a-icon a-icon--fill o-masthead-carousel__btn-icon" viewBox="0 0 16 16">
                            <path d="M8 0a8 8 0 108 8 8 8 0 00-8-8zm3.34 8.62l-3.83 3.83a.87.87 0 11-1.24-1.23L9.49 8 6.27 4.78a.87.87 0 010-1.23.88.88 0 011.24 0l3.83 3.83a.89.89 0 010 1.24z" />
                        </svg>
                    </ButtonNext>
                    <DotGroup className="o-masthead-carousel__pagination-dots" />
                </CarouselProvider>
                {(image?.url || video?.url) && (
                    <div className="o-masthead-carousel__media-container">
                        {image?.url && (
                            <>
                                {supportsPicture && (
                                    <Img
                                        alt={(image.alt) ? image.alt : ''}
                                        className="o-masthead-carousel__image"
                                        fluid={{
                                            aspectRatio: image?.dimensions?.width / image?.dimensions?.height,
                                            src: `${image?.url}&w=50`,
                                            srcSet: `${image?.url}&w=375 375w, ${image?.url}&w=480 480w, ${image?.url}&w=768 768w, ${image?.url}&w=960 960w, ${image?.url}&w=1280 1280w, ${image?.url}&w=1920 1920w`,
                                            sizes: '(max-width: 1000px) 100vw, 1000px',
                                        }}
                                        objectFit="cover"
                                        objectPosition="50% 50%"
                                    />
                                )}
                                {!supportsPicture && (
                                    <img alt={(image.alt) ? image.alt : ''} className="o-masthead-carousel__image" src={`${image?.url}&w=1920`} />
                                )}
                            </>
                        )}
                        {(loadVideo && video?.url) && (
                            <video autoPlay={true} loop={true} muted={true} playsInline={true} ref={playerRef} className="o-masthead-carousel__video" src={video?.url} />
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

MastheadCarousel.propTypes = propTypes;
MastheadCarousel.defaultProps = defaultProps;

export default MastheadCarousel;
