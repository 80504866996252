import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconYouTube({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 16 16">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M6.348 10.157V5.629l4.323 2.272-4.323 2.256zm9.492-5.312s-.156-1.11-.636-1.6c-.609-.642-1.29-.645-1.603-.682C11.362 2.4 8.003 2.4 8.003 2.4h-.006s-3.359 0-5.598.163c-.313.037-.994.04-1.603.682-.48.49-.636 1.6-.636 1.6S0 6.149 0 7.453v1.222c0 1.304.16 2.608.16 2.608s.156 1.11.635 1.6c.61.641 1.409.621 1.765.688 1.28.124 5.44.162 5.44.162s3.362-.005 5.601-.168c.313-.038.994-.04 1.603-.683.48-.489.636-1.599.636-1.599S16 9.98 16 8.675V7.453c0-1.304-.16-2.608-.16-2.608z" />
            </svg>
        </>
    );
}

IconYouTube.propTypes = propTypes;
IconYouTube.defaultProps = defaultProps;

export default IconYouTube;
