import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'atoms';

import './Button.scss';

const defaultProps = {
    alignment: '',
    className: '',
    devLink: {},
    link: {},
    linkText: '',
};

const propTypes = {
    alignment: PropTypes.string,
    className: PropTypes.string,
    devLink: PropTypes.shape({
        _meta: PropTypes.object,
        url: PropTypes.string,
    }),
    link: PropTypes.shape({
        _meta: PropTypes.object,
        url: PropTypes.string,
    }),
    linkText: PropTypes.string,
};

function Button({
    alignment,
    className,
    devLink,
    link,
    linkText,
}) {
    const linkUrl = (process.env.GATSBY_ACTIVE_ENV === 'development') ? devLink : link;

    return (
        <>
            <div className={`m-button m-button--align-${alignment.toLowerCase()} ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="m-button__container">
                        {(linkText && link?.link_type !== 'Any') && (
                            <Link className="m-button__button a-button a-button--fill a-button--large" to={linkUrl}>{linkText}</Link>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
