import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'atoms';

import './MastheadBlogIndividual.scss';

const defaultProps = {
    authorImage: {},
    authorName: '',
    className: '',
    category: '',
    date: '',
    dateFormatted: '',
    image: {},
    title: '',
};

const propTypes = {
    authorImage: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    authorName: PropTypes.string,
    className: PropTypes.string,
    category: PropTypes.string,
    date: PropTypes.string,
    dateFormatted: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    title: PropTypes.string,
};

function MastheadBlogIndividual({
    authorImage,
    authorName,
    category,
    className,
    date,
    dateFormatted,
    image,
    title,
}) {
    let supportsPicture = true;

    if (typeof window !== 'undefined') {
        supportsPicture = window?.HTMLPictureElement ? true : null;
    }

    return (
        <>
            <div className={`m-masthead-blog-individual l-grid l-grid--container ${className}`}>
                <div className="m-masthead-blog-individual__container">
                    <div className="m-masthead-blog-individual__content-container">
                        {title && (
                            <h1 className="m-masthead-blog-individual__title">
                                {title}
                            </h1>
                        )}
                        <div className="m-masthead-blog-individual__border">
                            <svg
                                className="m-masthead-blog-individual__border-descender"
                                viewBox="0 0 16 16"
                            >
                                <path d="M3.65 16H7.9L16 0H0v4h9.6L3.65 16z" />
                            </svg>
                        </div>
                        <ul className="m-masthead-blog-individual__meta-list">
                            {authorName && (
                                <li className="m-masthead-blog-individual__meta-item">
                                    <div className="m-masthead-blog-individual__avatar-container">
                                        {authorImage?.url && (
                                            <img
                                                alt={
                                                    authorImage.alt
                                                        ? authorImage.alt
                                                        : ''
                                                }
                                                className="m-masthead-blog-individual__avatar"
                                                loading="lazy"
                                                src={`${authorImage?.url}&w=72`}
                                            />
                                        )}
                                    </div>
                                    <span>by {authorName}</span>
                                </li>
                            )}
                            {date && (
                                <li className="m-masthead-blog-individual__meta-item">
                                    <time dateTime={date}>{dateFormatted}</time>
                                </li>
                            )}
                            {category && (
                                <li className="m-masthead-blog-individual__meta-item">
                                    <Link
                                        className="a-button a-button--small"
                                        to={{
                                            link_type: 'Document',
                                            uid: category,
                                        }}
                                    >
                                        {category}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="m-masthead-blog-individual__media-container">
                        {image?.url && (
                            <>
                                {supportsPicture && (
                                    <Img
                                        alt={image.alt ? image.alt : ''}
                                        fluid={{
                                            aspectRatio:
                                                image?.dimensions?.width /
                                                image?.dimensions?.height,
                                            src: `${image?.url}&w=50`,
                                            srcSet: `${image?.url}&w=375 375w, ${image?.url}&w=480 480w, ${image?.url}&w=768 768w, ${image?.url}&w=960 960w, ${image?.url}&w=1280 1280w`,
                                            sizes:
                                                '(max-width: 1000px) 100vw, 1000px',
                                        }}
                                    />
                                )}
                                {!supportsPicture && (
                                    <img
                                        alt={image.alt ? image.alt : ''}
                                        src={`${image?.url}&w=1920`}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

MastheadBlogIndividual.propTypes = propTypes;
MastheadBlogIndividual.defaultProps = defaultProps;

export default MastheadBlogIndividual;
