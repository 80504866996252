import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'atoms';

import './LogoItem.scss';

const defaultProps = {
    className: '',
    image: {
        url: '',
    },
    link: {},
};

const propTypes = {
    className: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        url: PropTypes.string,
    }),
    link: PropTypes.shape({
        _meta: PropTypes.object,
        url: PropTypes.string,
    }),
};

function LogoItem({
    className,
    image,
    link,
}) {
    return (
        <>
            <div className={`m-logo-item ${className}`}>
                {(image?.url && link?.link_type === 'Any') && (
                    <img alt={(image?.alt) ? image.alt : ''} className="m-logo-item__image" loading="lazy" src={image?.url} />
                )}
                {(image?.url && link?.link_type !== 'Any') && (
                    <Link className="m-logo-item__link" to={link}>
                        <img alt={(image?.alt) ? image.alt : ''} className="m-logo-item__image" loading="lazy" src={image?.url} />
                    </Link>
                )}
            </div>
        </>
    );
}

LogoItem.propTypes = propTypes;
LogoItem.defaultProps = defaultProps;

export default LogoItem;
