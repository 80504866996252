import React from 'react';
import PropTypes from 'prop-types';

import './Textarea.scss';

const defaultProps = {
    className: '',
    disabled: false,
    id: '',
    name: '',
    onChange: () => {},
    placeholder: null,
    required: false,
    rows: 4,
};

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rows: PropTypes.number,
};

function Input({
    className,
    disabled,
    id,
    name,
    onChange,
    placeholder,
    required,
    rows,
    ...rest
}) {
    return (
        <>
            <textarea className={`a-textarea ${className}`} disabled={disabled} id={id} name={name} onChange={onChange} placeholder={placeholder} required={required} rows={rows} {...rest} />
        </>
    );
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
