import React from 'react';
import PropTypes from 'prop-types';
import { Content } from 'molecules';

import './ValueItem.scss';

const defaultProps = {
    className: '',
    content: [],
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

function ValueItem({
    className,
    content,
    title,
}) {
    return (
        <>
            <div className={`m-value-item ${className}`}>
                {title && (
                    <h2 className="m-value-item__title">
                        <span className="m-value-item__title-inner">{title}</span>
                    </h2>
                )}
                {content && (
                    <Content className="m-value-item__content" content={content} />
                )}
            </div>
        </>
    );
}

ValueItem.propTypes = propTypes;
ValueItem.defaultProps = defaultProps;

export default ValueItem;
