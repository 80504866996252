import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import { Link } from 'atoms';

import './BlogItem.scss';

const defaultProps = {
    border: true,
    category: '',
    className: '',
    date: '',
    dateFormatted: '',
    description: '',
    image: {
        alt: '',
        url: '',
    },
    imageSize: 'large',
    link: {},
    title: '',
};

const propTypes = {
    border: PropTypes.bool,
    category: PropTypes.string,
    className: PropTypes.string,
    date: PropTypes.string,
    dateFormatted: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    imageSize: PropTypes.string,
    link: PropTypes.shape({
        _meta: PropTypes.object,
        url: PropTypes.string,
    }),
    title: PropTypes.string,
};

function BlogItem({
    border,
    category,
    className,
    date,
    dateFormatted,
    description,
    image,
    imageSize,
    link,
    title,
}) {
    const supportsPicture = typeof window !== 'undefined' ? window.HTMLPictureElement : null;
    const [ref, inView, entry] = useInView({
        threshold: 0.25,
        triggerOnce: true,
    });
    const inviewClassName = (!inView) ? '' : 'is-inview';
    const imageSrcSet = (image?.url && imageSize === 'large') ? `${image?.url}&w=375 375w, ${image?.url}&w=480 480w, ${image?.url}&w=768 768w` : `${image?.url}&w=192 192w`;

    return (
        <>
            <div className={`m-blog-item ${className} ${inviewClassName}`} ref={ref}>
                <div className="m-blog-item__container">
                    <div className="m-blog-item__image-container">
                        {image?.url && (
                            <>
                                {supportsPicture && (
                                    <Img
                                        alt={(image.alt) ? image.alt : ''}
                                        className="m-blog-item__image"
                                        fluid={{
                                            aspectRatio: image?.dimensions?.width / image?.dimensions?.height,
                                            src: `${image?.url}&w=50`,
                                            srcSet: imageSrcSet,
                                            sizes: '(max-width: 1000px) 100vw, 1000px',
                                        }}
                                        imgStyle={{ objectFit: 'contain' }}
                                    />
                                )}
                                {!supportsPicture && (
                                    <img alt={(image.alt) ? image.alt : ''} className="m-blog-item__image" src={`${image?.url}&w=768`} />
                                )}
                            </>
                        )}
                    </div>
                    {title && (
                        <h2 className="m-blog-item__title">
                            <Link className="m-blog-item__link" to={link}>{title}</Link>
                        </h2>
                    )}
                    {description && (
                        <p className="m-blog-item__description">{description}</p>
                    )}
                    {border && (
                        <div className="m-blog-item__border">
                            <svg className="m-blog-item__border-descender" viewBox="0 0 16 16">
                                <path d="M3.65 16H7.9L16 0H0v4h9.6L3.65 16z" />
                            </svg>
                        </div>
                    )}
                    {(category || date) && (
                        <div className="m-blog-item__footer">
                            {category && (
                                <Link className="m-blog-item__button a-button a-button--small" to={{ link_type: 'Document', uid: category }}>{category}</Link>
                            )}
                            {date && (
                                <time className="m-blog-item__date" dateTime={date}>{dateFormatted}</time>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

BlogItem.propTypes = propTypes;
BlogItem.defaultProps = defaultProps;

export default BlogItem;
