import React from 'react';
import PropTypes from 'prop-types';
import { IconClose, IconInfo, IconTick, IconWarning } from 'atoms';

import './Message.scss';

const defaultProps = {
    className: '',
    type: 'info',
};

const propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
};

function Message({
    className,
    message,
    type,
}) {

    return (
        <>
            <div className={`a-message ${className}`}>
                {type === 'error' && (
                    <IconClose className="a-message__icon" />
                )}
                {type === 'info' && (
                    <IconInfo className="a-message__icon" />
                )}
                {type === 'success' && (
                    <IconTick className="a-message__icon" />
                )}
                {type === 'warning' && (
                    <IconWarning className="a-message__icon" />
                )}
                <p className="a-message__content" dangerouslySetInnerHTML={{ __html: message }} />
            </div>
        </>
    );
}

Message.propTypes = propTypes;
Message.defaultProps = defaultProps;

export default Message;
