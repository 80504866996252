import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { useStaticQuery, graphql } from 'gatsby';
import { cx } from 'utility';
import { Button, IconClose } from 'atoms';

import './Cookiebar.scss';

const defaultProps = {
    className: '',
    text: '',
};

const propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
};

function useGlobalCookiebar() {
    const data = useStaticQuery(graphql`
        query GlobalCookiebar {
            allPrismicSite {
                nodes {
                    data {
                        cookie_popup_button_text {
                            text
                        }
                        cookie_popup_text {
                            text
                        }
                    }
                }
            }
        }
    `);

    return data;
}

function Cookiebar({
    className,
}) {
    const {
        allPrismicSite: {
            nodes
        }
    } = useGlobalCookiebar();
    const data = nodes?.[0]?.data;
    const buttonText = data.cookie_popup_button_text?.text;
    const text = data.cookie_popup_text?.text;

    const cookieName = 'OxburyCookiePolicy';
    const cookies = new Cookies();
    const [isCookiebarHidden, setIsCookiebarHidden] = useState(false);
    const hiddenClassName = isCookiebarHidden ? 'is-hidden' : '';
    const classes = cx(hiddenClassName, className);

    useEffect(function() {
        if (cookies.get(cookieName)) {
            setIsCookiebarHidden(true);
        }
    }, []);

    function dismissCookiebar() {
        setIsCookiebarHidden(!isCookiebarHidden);
        cookies.set(cookieName, 'true', { path: '/' });
    }

    return (
        <>
            <div className={`m-cookiebar ${classes}`}>
                <div className="l-grid l-grid--container">
                    <div className="m-cookiebar__container">
                        <div className="m-cookiebar__wrapper">
                            {text && (
                                <p className="m-cookiebar__text" dangerouslySetInnerHTML={{ __html: text }} />
                            )}
                            {buttonText && (
                                <Button className="m-cookiebar__button a-button--light a-button--small" onClick={dismissCookiebar} type="button">{buttonText}</Button>
                            )}
                        </div>
                        <Button aria-label={buttonText} className="m-cookiebar__close-button a-button--light a-button--small" onClick={dismissCookiebar} type="button"><IconClose /></Button>
                    </div>
                </div>
            </div>
        </>
    );
}

Cookiebar.propTypes = propTypes;
Cookiebar.defaultProps = defaultProps;

export default Cookiebar;
