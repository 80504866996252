import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import { cx } from 'utility';
import { Button } from 'atoms';
import { Content, SectionHeader } from 'molecules';

import './ContentImage.scss';

const defaultProps = {
    buttonText: '',
    buttonUrl: {},
    className: '',
    content: [],
    image1: {},
    image2: {},
    image3: {},
    image4: {},
    image5: {},
    imageSize: 'Default',
    reverseLayout: false,
    title: '',
    wideImage: false,
};

const propTypes = {
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.shape({
        _meta: PropTypes.object,
        url: PropTypes.string,
    }),
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    image1: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    image2: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    image3: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    image4: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    image5: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    imageSize: PropTypes.string,
    reverseLayout: PropTypes.bool,
    tableColour: PropTypes.string,
    title: PropTypes.string,
};

function ContentImage({
    buttonText,
    buttonUrl,
    className,
    content,
    image1,
    image2,
    image3,
    image4,
    image5,
    imageSize,
    reverseLayout,
    tableColour,
    title,
    titleIcon,
}) {
    const supportsPicture = typeof window !== 'undefined' ? window.HTMLPictureElement : null;
    const [ref, inView, entry] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    const inviewClassName = (!inView) ? '' : 'is-inview';
    const reverseLayoutClass = reverseLayout ? 'o-content-image--reverse-layout' : '';
    const imageSizeClass = imageSize ? `o-content-image--image-${imageSize.toLowerCase()}` : '';
    const classes = cx(reverseLayoutClass, imageSizeClass, inviewClassName, className);

    return (
        <>
            <div className={`o-content-image ${classes}`} ref={ref}>
                <div className="l-grid l-grid--container">
                    <div className="o-content-image__content-container">
                        {title && (
                            <SectionHeader className="o-content-image__title" icon={titleIcon} title={title} />
                        )}
                        {content && (
                            <Content className="o-content-image__content" content={content} tableColour={tableColour} />
                        )}
                        {(buttonText && buttonUrl?.link_type !== 'Any') && (
                            <Button className="o-content-image__button a-button--fill a-button--large" to={buttonUrl}>{buttonText}</Button>
                        )}
                    </div>
                    <div className="o-content-image__image-container">
                        {image1?.url && (
                            <div className="o-content-image__image-wrapper">
                                {supportsPicture && (
                                    <Img
                                        alt={(image1.alt) ? image1.alt : ''}
                                        className="o-content-image__image"
                                        fluid={{
                                            aspectRatio: image1.dimensions.width / image1.dimensions.height,
                                            src: `${image1?.url}&w=50`,
                                            srcSet: `${image1?.url}&w=375 375w, ${image1?.url}&w=480 480w, ${image1?.url}&w=768 768w, ${image1?.url}&w=960 960w, ${image1?.url}&w=1280 1280w`,
                                            sizes: '(max-width: 1000px) 100vw, 1000px',
                                        }}
                                    />
                                )}
                                {!supportsPicture && (
                                    <img alt={(image1.alt) ? image1.alt : ''} className="o-content-image__image" src={`${image1?.url}&w=1280`} />
                                )}
                            </div>
                        )}
                        {image2?.url && (
                            <div className="o-content-image__image-wrapper">
                                {supportsPicture && (
                                    <Img
                                        alt={(image2.alt) ? image2.alt : ''}
                                        className="o-content-image__image"
                                        fluid={{
                                            aspectRatio: image2.dimensions.width / image2.dimensions.height,
                                            src: `${image2?.url}&w=50`,
                                            srcSet: `${image2?.url}&w=375 375w, ${image2?.url}&w=480 480w, ${image2?.url}&w=768 768w, ${image2?.url}&w=960 960w, ${image2?.url}&w=1280 1280w`,
                                            sizes: '(max-width: 1000px) 100vw, 1000px',
                                        }}
                                    />
                                )}
                                {!supportsPicture && (
                                    <img alt={(image2.alt) ? image2.alt : ''} className="o-content-image__image" src={`${image2?.url}&w=1280`} />
                                )}
                            </div>
                        )}
                        {image3?.url && (
                            <div className="o-content-image__image-wrapper">
                                {supportsPicture && (
                                    <Img
                                        alt={(image3.alt) ? image3.alt : ''}
                                        className="o-content-image__image"
                                        fluid={{
                                            aspectRatio: image3.dimensions.width / image3.dimensions.height,
                                            src: `${image3?.url}&w=50`,
                                            srcSet: `${image3?.url}&w=375 375w, ${image3?.url}&w=480 480w, ${image3?.url}&w=768 768w, ${image3?.url}&w=960 960w, ${image3?.url}&w=1280 1280w`,
                                            sizes: '(max-width: 1000px) 100vw, 1000px',
                                        }}
                                    />
                                )}
                                {!supportsPicture && (
                                    <img alt={(image3.alt) ? image3.alt : ''} className="o-content-image__image" src={`${image3?.url}&w=1280`} />
                                )}
                            </div>
                        )}
                        {image4?.url && (
                            <div className="o-content-image__image-wrapper">
                                {supportsPicture && (
                                    <Img
                                        alt={(image4.alt) ? image4.alt : ''}
                                        className="o-content-image__image"
                                        fluid={{
                                            aspectRatio: image4.dimensions.width / image4.dimensions.height,
                                            src: `${image4?.url}&w=50`,
                                            srcSet: `${image4?.url}&w=375 375w, ${image4?.url}&w=480 480w, ${image4?.url}&w=768 768w, ${image4?.url}&w=960 960w, ${image4?.url}&w=1280 1280w`,
                                            sizes: '(max-width: 1000px) 100vw, 1000px',
                                        }}
                                    />
                                )}
                                {!supportsPicture && (
                                    <img alt={(image4.alt) ? image4.alt : ''} className="o-content-image__image" src={`${image4?.url}&w=1280`} />
                                )}
                            </div>
                        )}
                        {image5?.url && (
                            <div className="o-content-image__image-wrapper">
                                {supportsPicture && (
                                    <Img
                                        alt={(image5.alt) ? image5.alt : ''}
                                        className="o-content-image__image"
                                        fluid={{
                                            aspectRatio: image5.dimensions.width / image5.dimensions.height,
                                            src: `${image5?.url}&w=50`,
                                            srcSet: `${image5?.url}&w=375 375w, ${image5?.url}&w=480 480w, ${image5?.url}&w=768 768w, ${image5?.url}&w=960 960w, ${image5?.url}&w=1280 1280w`,
                                            sizes: '(max-width: 1000px) 100vw, 1000px',
                                        }}
                                    />
                                )}
                                {!supportsPicture && (
                                    <img alt={(image5.alt) ? image5.alt : ''} className="o-content-image__image" src={`${image5?.url}&w=1280`} />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

ContentImage.propTypes = propTypes;
ContentImage.defaultProps = defaultProps;

export default ContentImage;
