import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
    Button,
    IconBurger,
    IconClose,
    IconSearch,
    IconUser,
    Link,
} from 'atoms';
import { Nav } from 'molecules';
import { Search } from 'organisms';

import './Header.scss';

const defaultProps = {
    className: '',
    hideNav: false,
    siteData: {},
};

const propTypes = {
    className: PropTypes.string,
    hideNav: PropTypes.bool,
    siteData: PropTypes.object,
};

function Header({ className, hideNav, siteData }) {
    if (!siteData) return null;
    const data = siteData?.data;
    const loginLink = data?.login_link;
    const loginLinkText = data?.login_link_text;
    const [isNavActive, setIsNavActive] = useState(false);
    const [menuText, setMenuText] = useState('Open Menu');
    const navActiveClassName = isNavActive ? 'is-active' : '';
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchText, setSearchText] = useState('Open Search');
    const searchActiveClassName = isSearchActive ? 'is-active' : '';
    function toggleNav() {
        setIsNavActive(!isNavActive);
        setIsSearchActive(false);
        isNavActive ? setMenuText('Open Menu') : setMenuText('Close Menu');
    }

    function toggleSearch() {
        setIsSearchActive(!isSearchActive);
        setIsNavActive(false);
        isSearchActive
            ? setSearchText('Open Search')
            : setSearchText('Close Search');
    }

    return (
        <div className="o-header-wrap">
            <header className={`o-header ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-header__container">
                        <a href="#main-content">Skip to content</a>

                        <Link
                            aria-label="Oxbury Home"
                            className="o-header__logo"
                            to={{
                                link_type: 'Document',
                                type: 'home_page',
                                uid: '',
                            }}
                        >
                            <svg
                                className="o-header__logo-image"
                                viewBox="0 0 201 37"
                            >
                                <path
                                    className="o-header__logo-text"
                                    d="M17.354.52c10.488 0 16.978 5.834 16.978 14.618s-6.49 14.618-16.978 14.618C6.866 29.756.31 23.922.31 15.138S6.931.52 17.354.52zm9.177 14.684c0-5.572-3.474-8.915-9.177-8.915-5.703 0-9.177 3.408-9.177 8.915 0 5.506 3.54 8.915 9.177 8.915s9.177-3.409 9.177-8.915zM43.575 18.494L35.25 8.334h8.26l4.129 5.572 4.326-5.572h8.457l-8.98 10.357 9.635 10.423H52.36l-4.851-5.834-4.654 5.834h-8.588l9.309-10.62zM77.138 8.072c7.21 0 12.127 4.326 12.127 10.881 0 6.424-4.917 10.882-11.996 10.882-3.278 0-5.638-.787-7.539-2.622v1.9h-6.49V1.32h6.49v9.505c1.639-1.836 4.064-2.753 7.407-2.753zm-1.18 16.847c3.802 0 6.227-2.295 6.227-5.9 0-3.606-2.425-5.965-6.227-5.965-3.802 0-6.228 2.294-6.228 5.9 0 3.605 2.425 5.965 6.228 5.965zM115.748 29.114h-6.556v-3.147a10.739 10.739 0 01-8.259 3.606c-3.671 0-6.293-1.18-7.8-3.278-1.18-1.704-1.246-3.802-1.246-6.555V8.268h6.555v10.03c0 4.13.852 6.03 4.588 6.03 3.737 0 6.162-2.163 6.162-5.834V8.268h6.556v20.846zM120.205 29.114V8.334h6.556v2.688c1.966-2.295 4.064-3.475 6.424-3.475.459 0 1.049.066 1.835.131v5.441c-.721-.065-1.376-.065-2.097-.065-3.802 0-6.162 2.49-6.162 6.686v9.308h-6.556v.066zM139.412 30.674h3.736c1.639 0 2.491-.655 2.491-1.9a2.93 2.93 0 00-.262-1.312L136.2 8.255h7.342l5.637 13.373 6.162-13.373h7.211l-11.93 23.861c-.918 1.901-2.033 3.147-3.344 3.802a8.46 8.46 0 01-3.408.525h-4.589l.131-5.769z"
                                />
                                <path
                                    className="o-header__logo-mark"
                                    d="M163.535 36.325l13.963-27.991h-5.703l-13.963 27.99h5.703zM174.941 36.325l13.963-27.991h-5.703l-13.963 27.99h5.703zM186.347 36.325L200.31 8.334h-5.703l-13.962 27.99h5.702z"
                                />
                            </svg>
                        </Link>
                        {!hideNav && (
                            <>
                                {loginLink?.link_type !== 'Any' && (
                                    <Button
                                        className="o-header__login-button"
                                        to={loginLink}
                                    >
                                        <IconUser className="o-header__login-button-user-icon" />
                                        {loginLinkText?.text}
                                    </Button>
                                )}
                                <Button
                                    aria-label={searchText}
                                    className={`o-header__search-button ${searchActiveClassName}`}
                                    onClick={toggleSearch}
                                    type="button"
                                >
                                    <IconSearch className="o-header__search-button-search-icon" />
                                    <IconClose className="o-header__search-button-close-icon" />
                                </Button>
                                <Button
                                    aria-label={menuText}
                                    className={`o-header__menu-button ${navActiveClassName}`}
                                    onClick={toggleNav}
                                    type="button"
                                >
                                    <IconBurger className="o-header__menu-button-burger-icon" />
                                    <IconClose className="o-header__menu-button-close-icon" />
                                </Button>
                                <Nav
                                    navActive={isNavActive}
                                    className={`o-header__nav ${navActiveClassName}`}
                                    searchActiveClassName={
                                        searchActiveClassName
                                    }
                                    searchText={searchText}
                                    siteData={siteData}
                                    toggleSearchOnClick={() => toggleSearch()}
                                />
                            </>
                        )}
                    </div>
                </div>

                {!hideNav && (
                    <Search
                        className={`o-header__search ${searchActiveClassName}`}
                        searchText={searchText}
                        toggleSearchOnClick={() => toggleSearch()}
                    />
                )}
                <div></div>
            </header>
        </div>
    );
}

export const query = graphql`
    fragment HeaderQuery on PrismicSite {
        data {
            login_link {
                link_type
                type
                uid
                url
            }
            login_link_text {
                text
            }
        }
    }
`;

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
