import React from 'react';
import PropTypes from 'prop-types';

import { SEO } from 'layout';
import { Notice } from 'molecules';
import { Footer, Header } from 'organisms';

const defaultProps = {
    className: '',
    children: null,
    hideFooter: false,
    hideHeader: false,
    hideNav: false,
    seoDescription: '',
    seoImage: '',
    seoTitle: '',
    siteData: {},
    urgentMessageDescription: [],
    urgentMessageShow: false,
    urgentMessageTitle: '',
};

const propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    hideFooter: PropTypes.bool,
    hideHeader: PropTypes.bool,
    hideNav: PropTypes.bool,
    pathname: PropTypes.string.isRequired,
    seoDescription: PropTypes.string,
    seoImage: PropTypes.string,
    seoTitle: PropTypes.string,
    siteData: PropTypes.object,
    urgentMessageDescription: PropTypes.arrayOf(PropTypes.object),
    urgentMessageShow: PropTypes.bool,
    urgentMessageTitle: PropTypes.string,
};

function Shell({
    className,
    children,
    hideFooter,
    hideHeader,
    hideNav,
    pathname,
    seoDescription,
    seoImage,
    seoTitle,
    siteData,
    urgentMessageDescription,
    urgentMessageShow,
    urgentMessageTitle,
}) {
    return (
        <div className={`l-grid l-shell ${className}`}>
            <SEO
                pageDescription={seoDescription}
                pageSocialImage={seoImage}
                pageTitle={seoTitle}
                pathname={pathname}
            />
            {!hideHeader && (
                <div className="l-shell__header">
                    {urgentMessageShow && (
                        <Notice
                            description={urgentMessageDescription}
                            title={urgentMessageTitle}
                        />
                    )}
                </div>
            )}
            <div className="l-shell__main">
                {!hideHeader && (
                    <Header hideNav={hideNav} siteData={siteData} />
                )}
                {children}
            </div>
            {!hideFooter && (
                <Footer className="l-shell__footer" siteData={siteData} />
            )}
        </div>
    );
}

Shell.propTypes = propTypes;
Shell.defaultProps = defaultProps;

export default Shell;
