import React from 'react';
import PropTypes from 'prop-types';

import './ChartItem.scss';

const defaultProps = {
    className: '',
    label: '',
    percentage: 0,
    percentageWidth: 0,
};

const propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    percentage: PropTypes.number,
    percentageWidth: PropTypes.number,
};

function Content({
    className,
    label,
    percentage,
    percentageWidth,
}) {
    const style = {
        animationDuration: `${percentageWidth}s`,
        width: `${percentageWidth * 100}%`
    };

    return (
        <>
            <div className={`m-chart-item ${className}`}>
                <dt className="m-chart-item__label">
                    {label}
                    <div className="m-chart-item__label-bar" style={style} />
                </dt>
                <dd className="m-chart-item__percentage">{percentage}</dd>
            </div>
        </>
    );
}

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
