import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconChevronDown({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--stroke ${className}`} viewBox="0 0 16 16">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M1 4.5l7 7 7-7" />
            </svg>
        </>
    );
}

IconChevronDown.propTypes = propTypes;
IconChevronDown.defaultProps = defaultProps;

export default IconChevronDown;
