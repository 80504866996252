import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
} from '@reach/accordion';
import { IconChevronDown } from 'atoms';
import { Content } from 'molecules';
import "@reach/accordion/styles.css";

import './AccordionListing.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function AccordionListing({
    className,
    listing,
}) {
    return (
        <>
            <Accordion className={`o-accordion-listing ${className}`} collapsible multiple>
                <div className="l-grid l-grid--container">
                    <div className="o-accordion-listing__container">
                        {listing.map(({ content, title }, index) => (
                            <InView key={`{${index}${Math.random()}}`} threshold={0.75} triggerOnce={true}>
                                {({ inView, ref, entry }) => (
                                    <AccordionItem className={`o-accordion__item ${(entry && !inView.isVisible) ? 'is-inview' : ''}`} ref={ref}>
                                        {title?.text && (
                                            <h3 className="o-accordion-item__title">
                                                <AccordionButton className="o-accordion-item__button">
                                                    {title?.text}
                                                    <IconChevronDown className="o-accordion-item__button-icon" />
                                                </AccordionButton>
                                            </h3>
                                        )}
                                        {content?.raw && (
                                            <AccordionPanel className="o-accordion-item__panel">
                                                <Content className="o-accordion-item__content" content={content?.raw} />
                                            </AccordionPanel>
                                        )}
                                    </AccordionItem>
                                )}
                            </InView>
                        ))}
                    </div>
                </div>
            </Accordion>
        </>
    );
}

AccordionListing.propTypes = propTypes;
AccordionListing.defaultProps = defaultProps;

export default AccordionListing;
