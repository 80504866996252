import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel
} from "@reach/tabs";
import { Content, InputField, SectionHeader } from 'molecules';

import './CarbonCalculator.scss';

const defaultProps = {
    className: '',
    content: [],
    image: {},
    interestRate: 0.7,
    panel1CarbonLabel: '',
    panel1DepositLabel: '',
    panel1InterestLabel: '',
    panel1TreeLabel: '',
    panel2CarbonLabel: '',
    panel2DepositLabel: '',
    panel2InterestLabel: '',
    panel2TreeLabel: '',
    panelIcon: '',
    tab1Title: '',
    tab2Title: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    interestRate: PropTypes.number,
    panel1CarbonLabel: PropTypes.string,
    panel1DepositLabel: PropTypes.string,
    panel1InterestLabel: PropTypes.string,
    panel1TreeLabel: PropTypes.string,
    panel2CarbonLabel: PropTypes.string,
    panel2DepositLabel: PropTypes.string,
    panel2InterestLabel: PropTypes.string,
    panel2TreeLabel: PropTypes.string,
    panelIcon: PropTypes.string,
    tab1Title: PropTypes.string,
    tab2Title: PropTypes.string,
    title: PropTypes.string,
};

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function CarbonCalculator({
    className,
    content,
    image,
    interestRate,
    panel1CarbonLabel,
    panel1DepositLabel,
    panel1InterestLabel,
    panel1TreeLabel,
    panel2CarbonLabel,
    panel2DepositLabel,
    panel2InterestLabel,
    panel2TreeLabel,
    panelIcon,
    tab1Title,
    tab2Title,
    title,
}) {
    const supportsPicture = typeof window !== 'undefined' ? window.HTMLPictureElement : null;
    const [state, setState] = useState({
        carbon: "21.00",
        deposit: "30,000",
        interest: "210",
        trees: "135",
    });
    const treesPlural = (Number(state.trees.replace(/,/g, '')) > 1) ? 's' : '';

    function handleDepositChange(event) {
        event.persist();

        const depositValue = numberWithCommas(event.target.value.replace(/[^0-9]/g, ''));
        const interestValue = numberWithCommas(Math.round((Number(depositValue.replace(/[^0-9]/g, '')) * interestRate) / 100));
        const carbonValue = numberWithCommas((Number(interestValue.replace(/[^0-9]/g, '')) / 10).toFixed(2));
        const treesValue = numberWithCommas(Math.round((Number(interestValue.replace(/,/g, '')) / 1.55)));

        setState((previousState) => {
            return {
                ...previousState,
                carbon: carbonValue,
                deposit: depositValue,
                interest: interestValue,
                trees: treesValue,
            }
        });
    }

    function handleCarbonChange(event) {
        event.persist();

        const carbonValue = numberWithCommas(event.target.value.replace(/[^0-9.]/g, ''));
        const depositValue = numberWithCommas(Math.round(((event.target.value.replace(/[^0-9.]/g, '') * 1000) / (interestRate * 10)) * 10));
        const interestValue = numberWithCommas(Math.round((Number(depositValue.replace(/[^0-9]/g, '')) * interestRate) / 100));
        const treesValue = numberWithCommas(Math.round((Number(interestValue.replace(/,/g, '')) / 1.55)));

        setState((previousState) => {
            return {
                ...previousState,
                carbon: carbonValue,
                deposit: depositValue,
                interest: interestValue,
                trees: treesValue,
            }
        });
    }

    return (
        <>
            <div className={`o-carbon-calculator ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-carbon-calculator__content-container">
                        {title && (
                            <SectionHeader
                                className="o-carbon-calculator__title"
                                title={title}
                            />
                        )}
                        {content && (
                            <Content
                                className="o-carbon-calculator__content"
                                content={content}
                            />
                        )}
                    </div>
                    <Tabs className="o-carbon-calculator__tab-container">
                        <TabList className="o-carbon-calculator__tab-list">
                            {tab1Title && (
                                <Tab className="o-carbon-calculator__tab">{tab1Title}</Tab>
                            )}
                            {tab2Title && (
                                <Tab className="o-carbon-calculator__tab">{tab2Title}</Tab>
                            )}
                        </TabList>

                        <TabPanels className="o-carbon-calculator__tab-panels">
                            {tab1Title && (
                                <TabPanel className="o-carbon-calculator__tab-panel">
                                    <div className="o-carbon-calculator__field-container">
                                        <InputField
                                            className="o-carbon-calculator__field m-form-field--large"
                                            id="carbon-calculator-deposit"
                                            label={panel1DepositLabel}
                                            name="deposit"
                                            onChange={handleDepositChange}
                                            preText="£"
                                            required
                                            type="text"
                                            value={state.deposit}
                                        />
                                        <div className="o-carbon-calculator__icon-container">
                                            {panelIcon?.url && (
                                                <img alt={(panelIcon.alt) ? panelIcon.alt : ''} className="o-carbon-calculator__icon" loading="lazy" src={panelIcon?.url} />
                                            )}
                                        </div>
                                    </div>
                                    <ul className="o-carbon-calculator__results-list">
                                        <li className="o-carbon-calculator__results-item">
                                            {panel1CarbonLabel && (
                                                <strong className="o-carbon-calculator__results-label">{panel1CarbonLabel}</strong>
                                            )}
                                            <span className="o-carbon-calculator__results-value-container">
                                                <span className="o-carbon-calculator__results-value">{state.carbon}</span>
                                            </span>
                                            <span className="o-carbon-calculator__results-additional-label">Tonnes C0<sub>2</sub></span>
                                        </li>
                                        <li className="o-carbon-calculator__results-item">
                                            {panel1InterestLabel && (
                                                <strong className="o-carbon-calculator__results-label">{panel1InterestLabel}</strong>
                                            )}
                                            <span className="o-carbon-calculator__results-value-container">
                                                <span className="o-carbon-calculator__results-value">£{state.interest}</span>
                                            </span>
                                            <span className="o-carbon-calculator__results-additional-label"></span>
                                        </li>
                                        <li className="o-carbon-calculator__results-item">
                                            {panel1TreeLabel && (
                                                <strong className="o-carbon-calculator__results-label">{panel1TreeLabel}</strong>
                                            )}
                                            <span className="o-carbon-calculator__results-value-container">
                                                <span className="o-carbon-calculator__results-value">{state.trees}</span>
                                            </span>
                                            <span className="o-carbon-calculator__results-additional-label">Tree{treesPlural}</span>
                                        </li>
                                    </ul>
                                </TabPanel>
                            )}
                            {tab2Title && (
                                <TabPanel className="o-carbon-calculator__tab-panel">
                                    <div className="o-carbon-calculator__field-container">
                                        <InputField
                                            className="o-carbon-calculator__field m-form-field--large"
                                            id="carbon-calculator-carbon"
                                            label={panel2CarbonLabel}
                                            name="carbon"
                                            onChange={handleCarbonChange}
                                            postText="Tonnes C0&lt;sub&gt;2&lt;/sub&gt;"
                                            required
                                            type="text"
                                            value={state.carbon}
                                        />
                                        <div className="o-carbon-calculator__icon-container">
                                            {panelIcon?.url && (
                                                <img alt={(panelIcon.alt) ? panelIcon.alt : ''} className="o-carbon-calculator__icon" loading="lazy" src={panelIcon?.url} />
                                            )}
                                        </div>
                                    </div>
                                    <ul className="o-carbon-calculator__results-list">
                                        <li className="o-carbon-calculator__results-item">
                                            {panel2DepositLabel && (
                                                <strong className="o-carbon-calculator__results-label">{panel2DepositLabel}</strong>
                                            )}
                                            <span className="o-carbon-calculator__results-value-container">
                                                <span className="o-carbon-calculator__results-value">£{state.deposit}</span>
                                            </span>
                                            <span className="o-carbon-calculator__results-additional-label"></span>
                                        </li>
                                        <li className="o-carbon-calculator__results-item">
                                            {panel2InterestLabel && (
                                                <strong className="o-carbon-calculator__results-label">{panel2InterestLabel}</strong>
                                            )}
                                            <span className="o-carbon-calculator__results-value-container">
                                                <span className="o-carbon-calculator__results-value">£{state.interest}</span>
                                            </span>
                                            <span className="o-carbon-calculator__results-additional-label"></span>
                                        </li>
                                        <li className="o-carbon-calculator__results-item">
                                            {panel2TreeLabel && (
                                                <strong className="o-carbon-calculator__results-label">{panel2TreeLabel}</strong>
                                            )}
                                            <span className="o-carbon-calculator__results-value-container">
                                                <span className="o-carbon-calculator__results-value">{state.trees}</span>
                                            </span>
                                            <span className="o-carbon-calculator__results-additional-label">Tree{treesPlural}</span>
                                        </li>
                                    </ul>
                                </TabPanel>
                            )}
                        </TabPanels>
                    </Tabs>
                </div>
                {image?.url && (
                    <div className="o-carbon-calculator__image-container">
                        <div className="o-carbon-calculator__image-wrapper">
                            {supportsPicture && (
                                <Img
                                    alt={(image.alt) ? image.alt : ''}
                                    className="o-carbon-calculator__image"
                                    fluid={{
                                        aspectRatio: image.dimensions.width / image.dimensions.height,
                                        src: `${image?.url}&w=50`,
                                        srcSet: `${image?.url}&w=375 375w, ${image?.url}&w=480 480w, ${image?.url}&w=768 768w, ${image?.url}&w=960 960w, ${image?.url}&w=1280 1280w`,
                                        sizes: '(max-width: 1000px) 100vw, 1000px',
                                    }}
                                />
                            )}
                            {!supportsPicture && (
                                <img alt={(image.alt) ? image.alt : ''} className="o-carbon-calculator__image" src={`${image?.url}&w=1280`} />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

CarbonCalculator.propTypes = propTypes;
CarbonCalculator.defaultProps = defaultProps;

export default CarbonCalculator;
