import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { Link } from 'atoms';

import './FeatureBlockItem.scss';

const defaultProps = {
    className: '',
    description: '',
    icon: {
        alt: '',
        url: '',
    },
    link: {},
    linkText: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    link: PropTypes.shape({
        _meta: PropTypes.object,
        url: PropTypes.string,
    }),
    linkText: PropTypes.string,
    title: PropTypes.string,
};

function FeatureBlockItem({
    className,
    description,
    icon,
    link,
    linkText,
    title,
}) {
    const [ref, inView, entry] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    const inviewClassName = (!inView) ? '' : 'is-inview';

    return (
        <>
            <div className={`m-feature-block-item ${className} ${inviewClassName}`} ref={ref}>
                {icon?.url && (
                    <img alt={(icon.alt) ? icon.alt : ''} className="m-feature-block-item__icon" loading="lazy" src={icon?.url} />
                )}
                {(title && !link?.link_type === 'Any') && (
                    <h2 className="m-feature-block-item__title">{title}</h2>
                )}
                {(title && link?.link_type !== 'Any') && (
                    <h2 className="m-feature-block-item__title">
                        <Link className="m-feature-block-item__link" to={link}>{title}</Link>
                    </h2>
                )}
                {description && (
                    <p className="m-feature-block-item__description">{description}</p>
                )}
                {(linkText && link?.link_type !== 'Any') && (
                    <Link className="m-feature-block-item__button a-button a-button--fill a-button--small" tabIndex="-1" to={link}>{linkText}</Link>
                )}
            </div>
        </>
    );
}

FeatureBlockItem.propTypes = propTypes;
FeatureBlockItem.defaultProps = defaultProps;

export default FeatureBlockItem;
