import React from 'react';
import {
    Image,
    Video,
} from 'atoms';
import {
    Blockquote,
    Button,
    Download,
    LinkListing,
    Notice,
    SavingsCTA,
} from 'molecules';
import {
    AccordionListing,
    CarbonCalculator,
    ChartListing,
    ContactForm,
    ContentHeader,
    ContentImage,
    ContentLogoListing,
    FeefoReviewCards,
    KeyInfoListingContent,
    LoanForm,
    TeamListing,
    ValueListing,
} from 'organisms';

function renderComponents(component, type, pathname) {
    switch (type) {
    case 'accordion':
        return (
            <AccordionListing
                listing={component?.items}
                key={`{${type}${Math.random()}}`}
            />
        );
    case 'blockquote':
        return (
            <Blockquote
                authorLink={component?.primary?.author_link}
                authorName={component?.primary?.author_name?.text}
                quote={component?.primary?.quote?.text}
                image={component?.primary?.background_image}
                key={`{${type}${Math.random()}}`}
            />
        );
    case 'button':
        return (
            <Button
                alignment={component?.primary?.alignment}
                key={`{${type}${Math.random()}}`}
                devLink={component?.primary?.button_dev_link}
                link={component?.primary?.button_link}
                linkText={component?.primary?.button_text?.text}
            />
        );
    case 'carbon_calculator':
        return (
            <CarbonCalculator
                content={component?.primary?.cc_content?.raw}
                image={component?.primary?.cc_image}
                panel1DepositLabel={component?.primary?.cc_panel_1_deposit_label?.text}
                panel1CarbonLabel={component?.primary?.cc_panel_1_carbon_label?.text}
                panel1InterestLabel={component?.primary?.cc_panel_1_interest_label?.text}
                panel1TreeLabel={component?.primary?.cc_panel_1_tree_label?.text}
                panel2DepositLabel={component?.primary?.cc_panel_2_deposit_label?.text}
                panel2CarbonLabel={component?.primary?.cc_panel_2_carbon_label?.text}
                panel2InterestLabel={component?.primary?.cc_panel_2_interest_label?.text}
                panel2TreeLabel={component?.primary?.cc_panel_2_tree_label?.text}
                panelIcon={component?.primary?.cc_panel_icon}
                tab1Title={component?.primary?.cc_tab_1_name?.text}
                tab2Title={component?.primary?.cc_tab_2_name?.text}
                title={component?.primary?.cc_title?.text}
                key={`{${type}${Math.random()}}`}
            />
        );
    case 'chart':
        return (
            <ChartListing
                chartColour={component?.primary?.chart_colour}
                chartContent={component?.primary?.chart_content?.raw}
                chartLabel={component?.primary?.chart_graph_label?.text}
                key={`{${type}${Math.random()}}`}
                listing={component?.items}
                title={component?.primary?.chart_title?.text}
            />
        );
    case 'contact_form':
        return (
            <ContactForm
                callTimeLabel={component?.primary?.contact_form_call_time_field_label?.text}
                content={component?.primary?.contact_form_content?.raw}
                emailLabel={component?.primary?.contact_form_email_field_label?.text}
                farmTypeLabel={component?.primary?.contact_form_farm_type_field_label?.text}
                farmTypeOptions={component?.primary?.contact_form_farm_type_field_options?.text}
                farmTypeZenSellFieldName={component?.primary?.contact_form_farm_type_zen_sell_field_name?.text}
                footerContent={component?.primary?.contact_form_footer_content?.raw}
                firstNameLabel={component?.primary?.contact_form_name_field_label?.text}
                key={`{${type}${Math.random()}}`}
                lastNameLabel={component?.primary?.contact_form_last_name_field_label?.text}
                landFarmedLabel={component?.primary?.contact_form_land_farmed_field_label?.text}
                landFarmedOptions={component?.primary?.contact_form_land_farmed_field_options?.text}
                loanValueLabel={component?.primary?.contact_form_loan_value_field_label?.text}
                loanValueOptions={component?.primary?.contact_form_loan_value_field_options?.text}
                messageLabel={component?.primary?.contact_form_message_field_label?.text}
                pathname={pathname}
                phoneLabel={component?.primary?.contact_form_phone_field_label?.text}
                postcodeLabel={component?.primary?.contact_form_postcode_field_label?.text}
                product={component?.primary?.contact_form_product?.text}
                submitText={component?.primary?.contact_form_submit_button_text?.text}
                subscribeEmailLabel={component?.primary?.contact_form_subscribe_email_checkbox_label?.text}
                subscribeNoneLabel={component?.primary?.contact_form_subscribe_none_checkbox_label?.text}
                subscribePostLabel={component?.primary?.contact_form_subscribe_post_checkbox_label?.text}
                subscribeSMSLabel={component?.primary?.contact_form_subscribe_sms_checkbox_label?.text}
                subscribeTelephoneLabel={component?.primary?.contact_form_subscribe_tel_checkbox_label?.text}
                title={component?.primary?.contact_form_title?.text}
            />
        );
    case 'content':
        return (
            <ContentHeader
                content={component?.primary?.content_content?.raw}
                key={`{${type}${Math.random()}}`}
                tableColour={component?.primary?.table_colour}
                title={component?.primary?.content_title?.text}
            />
        );
    case 'content___image':
    case 'content___image1':
        return (
            <ContentImage
                buttonText={component?.primary?.button_text?.text}
                buttonUrl={component?.primary?.button_url}
                content={component?.primary?.content?.raw}
                image1={component?.primary?.image_1}
                image2={component?.primary?.image_2}
                image3={component?.primary?.image_3}
                image4={component?.primary?.image_4}
                image5={component?.primary?.image_5}
                imageSize={component?.primary?.image_size}
                key={`{${type}${Math.random()}}`}
                reverseLayout={component?.primary?.reverse_layout}
                tableColour={component?.primary?.table_colour}
                title={component?.primary?.title?.text}
                titleIcon={component?.primary?.title_icon}
            />
        );
    case 'download':
        return (
            <Download
                fileName={component?.primary?.download_file_name?.text}
                link={component?.primary?.download_button_link}
                linkText={component?.primary?.download_button_text?.text}
                link2={component?.primary?.download_button_2_link}
                link2Text={component?.primary?.download_button_2_text?.text}
                key={`{${type}${Math.random()}}`}
            />
        );
    case 'feefo_review_cards':
        return (
            <FeefoReviewCards
                cards={component?.items}
                feefoReviewsIcon={component?.primary?.feefo_reviews_icon}
                feefoReviewsTitle={component?.primary?.feefo_reviews_title?.text}
                moreReviewsButtonText={component?.primary?.more_reviews_button_text?.text}
                moreReviewsButtonLink={component?.primary?.more_reviews_button_link?.url}
                key={`{${type}${Math.random()}}`}
            />
        );
    case 'image':
        return (
            <Image
                caption={component?.primary?.image_caption?.text}
                image={component?.primary?.image_image}
                key={`{${type}${Math.random()}}`}
            />
        );
    case 'key_info':
        return (
            <KeyInfoListingContent
                content={component?.primary?.key_info_content?.raw}
                key={`{${type}${Math.random()}}`}
                listing={component?.items}
            />
        );
    case 'loan_form':
        return (
            <LoanForm
                callTimeLabel={component?.primary?.loan_form_call_time_field_label?.text}
                content={component?.primary?.loan_form_content?.raw}
                emailLabel={component?.primary?.loan_form_email_field_label?.text}
                farmTypeLabel={component?.primary?.loan_form_farm_type_field_label?.text}
                farmTypeOptions={component?.primary?.loan_form_farm_type_field_options?.text}
                footerContent={component?.primary?.loan_form_footer_content?.raw}
                firstNameLabel={component?.primary?.loan_form_name_field_label?.text}
                landFarmedLabel={component?.primary?.loan_form_land_farmed_field_label?.text}
                landFarmedOptions={component?.primary?.loan_form_land_farmed_field_options?.text}
                lastNameLabel={component?.primary?.loan_form_last_name_field_label?.text}
                loanAmountLabel={component?.primary?.loan_form_loan_amount_field_label?.text}
                loanReasonLabel={component?.primary?.loan_form_loan_reason_field_label?.text}
                loanReasonOptions={component?.primary?.loan_form_loan_reason_field_options?.text}
                loanValueLabel={component?.primary?.loan_form_loan_value_field_label?.text}
                loanValueOptions={component?.primary?.loan_form_loan_value_field_options?.text}
                messageLabel={component?.primary?.loan_form_message_field_label?.text}
                key={`{${type}${Math.random()}}`}
                pathname={pathname}
                phoneLabel={component?.primary?.loan_form_phone_field_label?.text}
                postcodeLabel={component?.primary?.loan_form_postcode_field_label?.text}
                product={component?.primary?.loan_form_product?.text}
                submitText={component?.primary?.loan_form_submit_button_text?.text}
                subscribeEmailLabel={component?.primary?.loan_form_subscribe_email_checkbox_label?.text}
                subscribeNoneLabel={component?.primary?.loan_form_subscribe_none_checkbox_label?.text}
                subscribePostLabel={component?.primary?.loan_form_subscribe_post_checkbox_label?.text}
                subscribeSMSLabel={component?.primary?.loan_form_subscribe_sms_checkbox_label?.text}
                subscribeTelephoneLabel={component?.primary?.loan_form_subscribe_tel_checkbox_label?.text}
                termLengthLabel={component?.primary?.loan_form_term_length_field_label?.text}
                termLengthOptions={component?.primary?.loan_form_term_length_field_options?.text}
                title={component?.primary?.loan_form_title?.text}
            />
        );
    case 'logo_listing':
        return (
            <ContentLogoListing
                content={component?.primary?.logo_listing_content}
                key={`{${type}${Math.random()}}`}
                listing={component?.items}
                title={component?.primary?.logo_listing_title?.text}
            />
        );
    case 'notice_message':
        return (
            <Notice
                description={component?.primary?.notice_message_description?.raw}
                key={`{${type}${Math.random()}}`}
                title={component?.primary?.notice_message_title?.text}
            />
        );
    case 'quick_links':
        return (
            <LinkListing
                key={`{${type}${Math.random()}}`}
                listing={component?.items}
            />
        );
    case 'savings_cta':
    case 'savings_cta1':
        return (
            <SavingsCTA
                buttonBackgroundColour={component?.primary?.savings_cta_button_background_colour}
                buttonText={component?.primary?.savings_cta_button_text?.text}
                buttonUrl={component?.primary?.savings_cta_button_url}
                interestRate={component?.primary?.savings_cta_interest_rate?.text}
                interestRateLabel={component?.primary?.savings_cta_interest_rate_label?.text}
                key={`{${type}${Math.random()}}`}
                max={component?.primary?.savings_cta_max}
                min={component?.primary?.savings_cta_min}
                period={component?.primary?.savings_cta_period?.text}
                title={component?.primary?.savings_cta_title?.text}
            />
        );
    case 'team_listing':
        return (
            <TeamListing
                key={`{${type}${Math.random()}}`}
                gridLayout={component?.primary?.team_listing_grid_layout}
                listing={component?.items}
            />
        );
    case 'value_listing':
        return (
            <ValueListing
                key={`{${type}${Math.random()}}`}
                image1={component?.primary?.value_listing_image_1}
                image2={component?.primary?.value_listing_image_2}
                listing={component?.items}
                title={component?.primary?.value_listing_title?.text}
            />
        );
    case 'video':
        return (
            <Video
                caption={component?.primary?.video_caption?.text}
                key={`{${type}${Math.random()}}`}
                video={component?.primary?.video_video}
            />
        );
    default:
        return null;
    }
}

export { renderComponents };
