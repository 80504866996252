import React from 'react';
import PropTypes from 'prop-types';

import './KeyInfoItem.scss';

const defaultProps = {
    className: '',
    description: '',
    term: '',
};

const propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    term: PropTypes.string,
};

function KeyInfoItem({
    className,
    description,
    term,
}) {
    return (
        <>
            <div className={`m-key-info-item ${className}`}>
                {term && (
                    <dt className="m-key-info-item__term">{term}</dt>
                )}
                {description && (
                    <dd className="m-key-info-item__description">{description}</dd>
                )}
            </div>
        </>
    );
}

KeyInfoItem.propTypes = propTypes;
KeyInfoItem.defaultProps = defaultProps;

export default KeyInfoItem;
