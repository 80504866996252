import React from 'react';
import PropTypes from 'prop-types';
import { Content, SectionHeader } from 'molecules';
import { LogoListing } from 'organisms';

import './ContentLogoListing.scss';

const defaultProps = {
    className: '',
    content: [],
    listing: [],
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    listing: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

function ContentLogoListing({
    className,
    content,
    listing,
    title,
}) {
    return (
        <>
            <div className={`o-content-logo-listing ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-content-logo-listing__content-container">
                        {title && (
                            <SectionHeader className="o-content-logo-listing__title" title={title} />
                        )}
                        {content && (
                            <Content className="o-content-logo-listing__content" content={content} />
                        )}
                    </div>
                    {listing && (
                        <div className="o-content-logo-listing__logo-container">
                            <LogoListing listing={listing} />
                        </div>
                    )}
                    <div className="o-content-logo-listing__background" />
                </div>
            </div>
        </>
    );
}

ContentLogoListing.propTypes = propTypes;
ContentLogoListing.defaultProps = defaultProps;

export default ContentLogoListing;
