import React from 'react';
import PropTypes from 'prop-types';
import { Label, Select } from 'atoms';

import './SelectField.scss';

const defaultProps = {
    className: '',
    disabled: false,
    id: '',
    label: '',
    name: '',
    onChange: () => {},
    options: [],
    required: false,
    touched: false,
};

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.bool,
    touched: PropTypes.bool,
};

function SelectField({
    className,
    disabled,
    id,
    label,
    name,
    onChange,
    options,
    required,
    touched,
    ...rest
}) {
    return (
        <>
            <div className={`m-form-field ${className}`}>
                <Label
                    id={id}
                    label={label}
                    required={required}
                />
                <Select
                    disabled={disabled}
                    id={id}
                    name={name}
                    onChange={onChange}
                    options={options}
                    required={required}
                    touched={touched}
                />
            </div>
        </>
    );
}

SelectField.propTypes = propTypes;
SelectField.defaultProps = defaultProps;

export default SelectField;
