import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconLinkedIn({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 16 16">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M16 15.545h-3.536v-5.113c0-1.338-.553-2.252-1.77-2.252-.93 0-1.448.617-1.688 1.21-.09.214-.077.511-.077.808v5.347H5.426s.045-9.057 0-9.88H8.93v1.55c.207-.678 1.327-1.646 3.113-1.646C14.26 5.57 16 6.991 16 10.051v5.494zM1.883 4.428h-.022C.73 4.428 0 3.673 0 2.716 0 1.74.754 1 1.905 1c1.15 0 1.858.738 1.88 1.713 0 .957-.73 1.715-1.902 1.715zM.403 5.664h3.12v9.881H.402v-9.88z" />
            </svg>
        </>
    );
}

IconLinkedIn.propTypes = propTypes;
IconLinkedIn.defaultProps = defaultProps;

export default IconLinkedIn;
