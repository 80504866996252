import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconFacebook({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 16 16">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M9.39329 15.9999V8.70155H11.8431L12.2098 5.85726H9.39329V4.04126C9.39329 3.21779 9.62199 2.65655 10.803 2.65655L12.3091 2.65585V0.111955C12.0485 0.0774179 11.1545 0 10.1144 0C7.94282 0 6.45612 1.32545 6.45612 3.75971V5.85733H4V8.70163H6.45605V16L9.39329 15.9999Z" />
            </svg>
        </>
    );
}

IconFacebook.propTypes = propTypes;
IconFacebook.defaultProps = defaultProps;

export default IconFacebook;
