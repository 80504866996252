import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
    Button,
    IconChevronDown,
    IconChevronRight,
    IconClose,
    IconSearch,
    IconUser,
    Link,
} from 'atoms';
import isTouchDevice from 'utility/isTouchDevice';

import './Nav.scss';
import { useWindowSize } from 'react-use';

const defaultProps = {
    className: '',
    searchText: 'Search',
    toggleSearchOnClick: () => {},
};

const propTypes = {
    className: PropTypes.string,
    toggleSearchOnClick: PropTypes.func,
};

function Nav({
    navActive,
    className,
    searchActiveClassName,
    searchText,
    toggleSearchOnClick,
    siteData,
}) {
    if (!siteData) return null;
    const data = siteData?.data;
    const loginLink = data?.login_link;
    const loginLinkText = data?.login_link_text?.text;
    const navLinks = data?.body;
    const touchDevice = typeof window !== 'undefined' ? isTouchDevice() : null;
    const noTouchClassName = !touchDevice ? 'is-not-touch' : '';
    const [activeDropdown, setActiveDropdown] = useState(null);
    const activeDropdownClassName = 'is-active';
    const { width } = useWindowSize();

    function MappedNavList(props) {
        function handleActiveDropdown(event) {
            event.target.classList.contains(activeDropdownClassName)
                ? setActiveDropdown(null)
                : setActiveDropdown(event.target.id);
        }

        return (
            <>
                <ul className="m-nav__list">
                    {navLinks
                        .filter((node) =>
                            props.position.includes(
                                node?.primary?.nav_link_position
                            )
                        )
                        .map((node, linkIndex) => {
                            const navText = node?.primary?.nav_link_text?.text;
                            const navUrl = node?.primary?.nav_link_url;
                            const navId = navText
                                .replace(/\s+/g, '-')
                                .toLowerCase();
                            const navDropdown = node?.items;
                            const hasDropdown =
                                navDropdown[0]?.sub_nav_link_text?.text;
                            const navDropdownActive = activeDropdown === navId;
                            const navDropdownActiveClassName = navDropdownActive
                                ? activeDropdownClassName
                                : '';

                            return (
                                <div key={linkIndex}>
                                    {navUrl && (
                                        <li className="m-nav__item">
                                            {(!hasDropdown ||
                                                (hasDropdown &&
                                                    !touchDevice)) && (
                                                <Link
                                                    className="m-nav__link"
                                                    partiallyActive={true}
                                                    to={navUrl}
                                                >
                                                    {navText}{' '}
                                                    {hasDropdown && (
                                                        <IconChevronDown className="m-nav__link-dropdown-icon" />
                                                    )}
                                                </Link>
                                            )}

                                            {hasDropdown && (
                                                <>
                                                    {touchDevice && (
                                                        <button
                                                            className={`m-nav__link ${navDropdownActiveClassName}`}
                                                            id={navId}
                                                            onClick={
                                                                handleActiveDropdown
                                                            }
                                                            type="button"
                                                        >
                                                            {navText}{' '}
                                                            <IconChevronDown className="m-nav__link-dropdown-icon" />
                                                        </button>
                                                    )}

                                                    <div
                                                        className={`m-nav__dropdown-container ${navDropdownActiveClassName}`}
                                                    >
                                                        <ul className="m-nav__dropdown">
                                                            {navDropdown.map(
                                                                (
                                                                    dropdownnode,
                                                                    dropdownIndex
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            dropdownIndex
                                                                        }
                                                                        className="m-nav__dropdown-item"
                                                                    >
                                                                        <Link
                                                                            className="m-nav__dropdown-link"
                                                                            to={
                                                                                dropdownnode?.sub_nav_link_url
                                                                            }
                                                                        >
                                                                            {
                                                                                dropdownnode
                                                                                    ?.sub_nav_link_text
                                                                                    ?.text
                                                                            }{' '}
                                                                            <IconChevronRight className="m-nav__dropdown-link-icon" />
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </>
                                            )}
                                        </li>
                                    )}
                                </div>
                            );
                        })}
                </ul>
            </>
        );
    }

    return (
        <>
            <nav className={`m-nav ${noTouchClassName} ${className}`}>
                <MappedNavList
                    position={width < 1024 ? ['top', 'bottom'] : ['top']}
                ></MappedNavList>
                <Button
                    aria-label={searchText}
                    className={`m-nav__search-button a-button--light ${searchActiveClassName}`}
                    onClick={toggleSearchOnClick}
                    type="button"
                >
                    <IconSearch className="m-nav__search-button-search-icon" />
                    <IconClose className="m-nav__search-button-close-icon" />
                </Button>
                {loginLink && (
                    <Button
                        className="m-nav__login-button a-button--light"
                        to={loginLink}
                    >
                        <IconUser className="m-nav__login-button-user-icon" />
                        {loginLinkText}
                    </Button>
                )}
            </nav>
            <div className="m-nav-break" />
            <nav className={`m-nav-bottom ${noTouchClassName}`}>
                <div className="m-nav-bottom-wrap">
                    <div className="l-grid l-grid--container">
                        <div className="o-header__container">
                            <MappedNavList
                                className={`m-mapped-nav-list ${className}`}
                                position={['bottom']}
                            ></MappedNavList>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export const query = graphql`
    fragment NavQuery on PrismicSite {
        data {
            body {
                ... on PrismicSiteBodyNavLink {
                    items {
                        sub_nav_link_text {
                            text
                        }
                        sub_nav_link_url {
                            link_type
                            type
                            uid
                            url
                        }
                    }
                    primary {
                        nav_link_text {
                            text
                        }
                        nav_link_url {
                            link_type
                            type
                            uid
                            url
                        }
                        nav_link_position
                    }
                }
            }
            login_link {
                link_type
                type
                uid
                url
            }
            login_link_text {
                text
            }
        }
    }
`;

Nav.propTypes = propTypes;
Nav.defaultProps = defaultProps;

export default Nav;
