import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconTick } from 'atoms';

import './Checkbox.scss';

const defaultProps = {
    checked: false,
    className: '',
    disabled: false,
    id: '',
    name: '',
    onChange: () => {},
    required: false,
    text: '',
    value: undefined,
};

const propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    text: PropTypes.string,
    value: PropTypes.string,
};

function Checkbox({
    checked,
    className,
    disabled,
    id,
    name,
    onChange,
    required,
    text,
    value,
    ...rest
}) {
    return (
        <>
            <div className={`a-checkbox ${className}`}>
                <input className="a-checkbox__input" checked={checked} disabled={disabled} id={id} name={name} onChange={onChange} required={required} type="checkbox" value={value} />
                <label className="a-checkbox__label" htmlFor={id}>
                    <span className="a-checkbox__box">
                        <IconTick className="a-checkbox__icon" />
                    </span>
                    {text}
                </label>
            </div>
        </>
    );
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
