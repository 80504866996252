import React from 'react';
import PropTypes from 'prop-types';
import { LogoItem } from 'molecules';

import './LogoListing.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function FeatureBlockListing({
    className,
    listing,
}) {
    return (
        <>
            <div className={`o-logo-listing ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-logo-listing__container">
                        {listing.map(({ logo_listing_logo, logo_listing_link }, index) => (
                            logo_listing_logo && (
                                <LogoItem
                                    className="o-logo-listing__item"
                                    image={logo_listing_logo}
                                    key={`{${index}${Math.random()}}`}
                                    link={logo_listing_link}
                                />
                            )
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

FeatureBlockListing.propTypes = propTypes;
FeatureBlockListing.defaultProps = defaultProps;

export default FeatureBlockListing;
