import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconTick({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--stroke ${className}`} viewBox="0 0 16 16">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M2 9l5 4 7-10" />
            </svg>
        </>
    );
}

IconTick.propTypes = propTypes;
IconTick.defaultProps = defaultProps;

export default IconTick;
