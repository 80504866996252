import React from 'react';
import PropTypes from 'prop-types';
import { BlogItem } from 'molecules';

import './BlogListingRelated.scss';

const defaultProps = {
    className: '',
    listing: [],
    title: 'Related posts',
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

function BlogListingRelated({
    className,
    listing,
    title,
}) {
    return (
        <>
            <div className={`o-blog-listing-related__container ${className}`}>
                <div className="o-blog-listing-related__header">
                    <h2 className="o-blog-listing-related__title">{title}</h2>
                    <div className="o-blog-listing-related__border">
                        <svg className="o-blog-listing-related__border-descender" viewBox="0 0 16 16">
                            <path d="M3.65 16H7.9L16 0H0v4h9.6L3.65 16z" />
                        </svg>
                    </div>
                </div>
                <div className="o-blog-listing-related__item-container">
                    {listing.map(({ category, date, dateFormatted, description, image, link, title }, index) => (
                        <BlogItem
                        border={false}
                        category={category}
                        className="o-blog-listing-related__item"
                        date={date}
                        dateFormatted={dateFormatted}
                        description={description?.text}
                        image={image}
                        imageSize="small"
                        key={`blog-item-related-${index}`}
                        link={link}
                        title={title?.text}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

BlogListingRelated.propTypes = propTypes;
BlogListingRelated.defaultProps = defaultProps;

export default BlogListingRelated;
