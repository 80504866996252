import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { ChartItem, Content } from 'molecules';

import './ChartListing.scss';

const defaultProps = {
    chartColour: '',
    chartContent: [],
    chartLabel: '',
    className: '',
    listing: [],
    title: '',
};

const propTypes = {
    chartColour: PropTypes.string,
    chartContent: PropTypes.arrayOf(PropTypes.object),
    chartLabel: PropTypes.string,
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

function ChartListing({
    chartColour,
    chartContent,
    chartLabel,
    className,
    listing,
    title,
}) {
    const [ref, inView, entry] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    const inviewClassName = (!inView) ? '' : 'is-inview';
    const largestPercentage = Math.max.apply(Math, listing.map(function(obj) { return obj.chart_percentage; }));

    return (
        <>
            <div className={`o-chart-listing ${className} ${inviewClassName}`} ref={ref}>
                <div className="l-grid l-grid--container">
                    {title && (
                        <div className="o-chart-listing__title-container">
                            <h2 className="o-chart-listing__title">{title}</h2>
                        </div>
                    )}
                    {chartContent && (
                        <div className="o-chart-listing__content-container">
                            <Content className="o-chart-listing__content" content={chartContent} />
                        </div>
                    )}
                    {listing && (
                        <div className="o-chart-listing__item-container">
                            <span className="o-chart-listing__item-label">{chartLabel}</span>
                            <dl className="o-chart-listing__item-wrapper">
                                {listing.map(({ chart_label, chart_percentage }, index) => (
                                    <ChartItem
                                        className={`m-chart-item--${chartColour.toLowerCase()} o-chart-listing__item`}
                                        key={`{${index}${Math.random()}}`}
                                        label={chart_label?.text}
                                        percentage={chart_percentage}
                                        percentageWidth={chart_percentage / largestPercentage}
                                    />
                                ))}
                            </dl>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

ChartListing.propTypes = propTypes;
ChartListing.defaultProps = defaultProps;

export default ChartListing;
