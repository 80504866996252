import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import * as JsSearch from 'js-search';
import { Button, IconClose, Link } from 'atoms';
import { InputField } from 'molecules';

import './Search.scss';

const defaultProps = {
    className: '',
    searchText: '',
    toggleSearchOnClick: () => {},
};

const propTypes = {
    className: PropTypes.string,
    searchText: PropTypes.string,
    toggleSearchOnClick: PropTypes.func,
};

function Search({
    className,
    searchText,
    toggleSearchOnClick,
}) {
    const [pages, setPages] = useState([]);
    const [search, setSearch] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(function() {
        Axios.get("/data-search.json")
        .then(result => {
            const searchData = result.data;
            setPages(searchData.pages);
        })
        .catch(err => {
            setIsError(true);
            console.log(`Something bad happened while fetching the data\n${err}`);
        });
    }, []);

    useEffect(() => {
        rebuildIndex();
    }, [pages])

    // rebuilds the overall index based on the options
    function rebuildIndex() {
        const dataToSearch = new JsSearch.Search("id");

        // defines a indexing strategy for the data more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
        dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();

        // defines the sanitizer for the search to prevent some of the words from being excluded
        dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();

        // defines the search index read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
        dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id");

        dataToSearch.addIndex("metaDescription"); // sets the index attribute for the data
        dataToSearch.addIndex("metaTitle"); // sets the index attribute for the data
        dataToSearch.addIndex("title"); // sets the index attribute for the data
        dataToSearch.addIndex("uid"); // sets the index attribute for the data

        dataToSearch.addDocuments(pages); // adds the data to be searched
        setSearch(dataToSearch);
        setIsLoading(false);
    }

    // handles the input change and perform a search with js-search in which the results will be added to the state
    function searchData(e) {
        const queryResult = search.search(e.target.value);
        setSearchQuery(e.target.value);
        setSearchResults(queryResult);
    }

    function handleSubmit(e) {
        e.preventDefault();
    }

    return (
        <>
            <div className={`o-search ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-search__container">
                        <form className="o-search__form" onSubmit={handleSubmit}>
                            <InputField
                                className="o-search__input-field"
                                id="search-field"
                                label="Search"
                                name="search"
                                onChange={searchData}
                                placeholder="Search"
                            />
                            <Button aria-label={searchText} className="o-search__search-button" onClick={toggleSearchOnClick} type="button">
                                <IconClose className="o-search__search-button-close-icon" />
                            </Button>
                        </form>

                        {searchResults.length > 0 && (
                            <div className="o-search__results">
                                <span className="o-search__results-count">Results: {searchResults.length}</span>

                                <ul className="o-search__results-list">
                                    {searchResults.map(item => {
                                        return (
                                            <li className="o-search__results-item" key={item.id}>
                                                <Link className="o-search__results-link" to={{link_type: 'Document', type: item.type, uid: item.uid}}>{item.metaTitle}</Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

export default Search;
