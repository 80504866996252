import React from 'react';
import PropTypes from 'prop-types';
import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel
} from "@reach/tabs";
import { renderComponents } from 'utility';

import './TabListing.scss';

const defaultProps = {
    className: '',
    panelListing: [],
    tabListing: [],
};

const propTypes = {
    className: PropTypes.string,
    panelListing: PropTypes.arrayOf(PropTypes.array),
    tabListing: PropTypes.arrayOf(PropTypes.object),
};

function TabListing({
    className,
    panelListing,
    tabListing,
}) {
    return (
        <>
            <div className={`o-tab-listing ${className}`}>
                <div className="l-grid l-grid--container">
                    <Tabs className="o-tab-listing__container">
                        <TabList className="o-tab-listing__tab-list">
                            {tabListing.map(({ text }, index) => (
                                <Tab className="o-tab-listing__tab" key={`{${index}${Math.random()}}`}>{text}</Tab>
                            ))}
                        </TabList>

                        <TabPanels className="o-tab-listing__tab-panels">
                            {panelListing.map(node => (
                                <TabPanel className="o-tab-listing__tab-panel" key={`{${Math.random()}}`}>
                                    {node?.map(component => (
                                        renderComponents(component, component?.slice_type)
                                    ))}
                                </TabPanel>
                            ))}
                        </TabPanels>
                    </Tabs>
                </div>
            </div>
        </>
    );
}

TabListing.propTypes = propTypes;
TabListing.defaultProps = defaultProps;

export default TabListing;
