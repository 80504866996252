import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'atoms';
import { Masthead } from 'molecules';

import './MastheadBlogCategories.scss';

const defaultProps = {
    categories: [],
    className: '',
    description: '',
    image: {},
    pageUrl: '',
    strapline: '',
    title: '',
    video: {},
    viewAllButton: '',
};

const propTypes = {
    categories: PropTypes.array,
    className: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    pageUrl: PropTypes.string,
    strapline: PropTypes.string,
    title: PropTypes.string,
    video: PropTypes.object,
    viewAllButton: PropTypes.string,
};

function MastheadBlogCategories({
    categories,
    className,
    description,
    image,
    pageUrl,
    strapline,
    title,
    video,
    viewAllButton,
}) {
    return (
        <>
            <div className={`o-masthead-blog-categories ${className}`}>
                <Masthead
                    className="o-masthead-blog-categories__masthead"
                    description={description}
                    image={image}
                    pageUrl={pageUrl}
                    socialShare={true}
                    strapline={strapline}
                    title={title}
                    video={video}
                />
                <div className="o-masthead-blog-categories__categories">
                    <div className="l-grid l-grid--container">
                        <ul className="o-masthead-blog-categories__categories-container">
                            {categories.map(({ icon, name }, index) => (
                                (name) && (
                                    <li className="o-masthead-blog-categories__categories-item" key={`masthead-category-${index}`}>
                                        <Link className="o-masthead-blog-categories__categories-item-link" to={{ link_type: 'Document', uid: name }}>
                                            {icon?.url && (
                                                <img alt={(icon.alt) ? icon.alt : ''} className="o-masthead-blog-categories__categories-item-icon" loading="lazy" src={`${icon?.url}&w=128`} />
                                            )}
                                            {name && (
                                                <span className="o-masthead-blog-categories__categories-item-name">{name}</span>
                                            )}
                                        </Link>
                                    </li>
                                )
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {viewAllButton && (
                <div className="o-masthead-blog-categories__view-all-container">
                    <div className="l-grid l-grid--container">
                        <div className="o-masthead-blog-categories__view-all-wrapper">
                            <Link className="a-button" to={{ link_type: 'Document', uid: viewAllButton }}>View all posts</Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

MastheadBlogCategories.propTypes = propTypes;
MastheadBlogCategories.defaultProps = defaultProps;

export default MastheadBlogCategories;
