import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'utility';
import { TeamItem } from 'molecules';

import './TeamListing.scss';

const defaultProps = {
    className: '',
    gridLayout: false,
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    gridLayout: PropTypes.bool,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function TeamListing({
    className,
    gridLayout,
    listing,
}) {
    const gridLayoutClass = gridLayout ? 'o-team-listing--grid-layout' : '';
    const classes = cx(gridLayoutClass, className);

    return (
        <>
            <div className={`o-team-listing ${classes}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-team-listing__container">
                        {listing.map(({ team_listing_content, team_listing_image, team_listing_job_title, team_listing_name }, index) => (
                            <TeamItem
                                className="o-team-listing__item"
                                content={team_listing_content?.raw}
                                image={team_listing_image}
                                jobTitle={team_listing_job_title?.text}
                                key={`{${index}${Math.random()}}`}
                                title={team_listing_name?.text}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

TeamListing.propTypes = propTypes;
TeamListing.defaultProps = defaultProps;

export default TeamListing;
