import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import { Content } from 'molecules';

import './TeamItem.scss';

const defaultProps = {
    className: '',
    content: [],
    image: {},
    jobTitle: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.object,
    }),
    jobTitle: PropTypes.string,
    title: PropTypes.string,
};

function TeamItem({
    className,
    content,
    image,
    jobTitle,
    title,
}) {
    const supportsPicture = typeof window !== 'undefined' ? window.HTMLPictureElement : null;
    let offset = 0.75;
    if (typeof window !== 'undefined' && window.matchMedia('(max-width: 600px)').matches) {
        offset = 0.25;
    }

    const [ref, inView, entry] = useInView({
        threshold: offset,
        triggerOnce: true,
    });
    const inviewClassName = (!inView) ? '' : 'is-inview';

    return (
        <>
            <div className={`m-team-item ${className} ${inviewClassName}`} ref={ref}>
                {(title || jobTitle || content) && (
                    <div className="m-team-item__content-container">
                        {title && (
                            <h2 className="m-team-item__title">{title}</h2>
                        )}
                        {jobTitle && (
                            <span className="m-team-item__job-title">{jobTitle}</span>
                        )}
                        {content && (
                            <Content className="m-team-item__content" content={content} />
                        )}
                    </div>
                )}
                {image?.url && (
                    <div className="m-team-item__image-container">
                        {supportsPicture && (
                            <Img
                                alt={(image.alt) ? image.alt : ''}
                                className="m-team-item__image"
                                fluid={{
                                    aspectRatio: image.dimensions.width / image.dimensions.height,
                                    src: `${image?.url}&w=50`,
                                    srcSet: `${image?.url}&w=375 375w, ${image?.url}&w=480 480w, ${image?.url}&w=768 768w, ${image?.url}&w=960 960w`,
                                    sizes: '(max-width: 1000px) 100vw, 1000px',
                                }}
                            />
                        )}
                        {!supportsPicture && (
                            <img alt={(image.alt) ? image.alt : ''} className="m-team-item__image" src={`${image?.url}&w=960`} />
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

TeamItem.propTypes = propTypes;
TeamItem.defaultProps = defaultProps;

export default TeamItem;
