const linkResolverSitemap = require('./linkResolverSitemap.json');

const linkResolver = ({ node, key, value } = {}) => (doc) => {
    const linkType = doc.link_type;

    function returnFullPageSlug(uid, anchorId) {
        const object = linkResolverSitemap.pages.find((obj) => obj.uid === uid);
        return object?.slug ? object.slug : `/${uid}`;
    }

    if (linkType === 'Media' || linkType === 'Web') {
        return doc.url;
    } else if (linkType === 'Document') {
        return returnFullPageSlug(doc.uid);
    }
};

export { linkResolver };
