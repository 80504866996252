
import React from 'react';
import PropTypes from 'prop-types';
import { Link, IconFacebook, IconLinkedIn, IconTwitter } from 'atoms';

import './SocialShare.scss';

const defaultProps = {
    className: '',
    pageUrl: '',
};

const propTypes = {
    className: PropTypes.string,
    pageUrl: PropTypes.string,
};

function SocialShare({
    className,
    pageUrl,
}) {
    return (
        <div className={`m-social-share ${className}`}>
            <strong className="m-social-share__title">Share</strong>
            <ul className="m-social-share__list">
                <li className="m-social-share__item">
                    <Link aria-label="Share on LinkedIn" className="m-social-share__button" to={{ link_type: 'Web', url: `https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}` }}>
                        <IconLinkedIn />
                    </Link>
                </li>
                <li className="m-social-share__item">
                    <Link aria-label="Share on Twitter" className="m-social-share__button" to={{ link_type: 'Web', url: `https://twitter.com/intent/tweet?url=${pageUrl}` }}>
                        <IconTwitter />
                    </Link>
                </li>
                <li className="m-social-share__item">
                    <Link aria-label="Share on Facebook" className="m-social-share__button" to={{ link_type: 'Web', url: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}` }}>
                        <IconFacebook />
                    </Link>
                </li>
            </ul>
        </div>
    );
}

SocialShare.propTypes = propTypes;
SocialShare.defaultProps = defaultProps;

export default SocialShare;
