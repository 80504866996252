import React from 'react';
import PropTypes from 'prop-types';

import './ContactPanel.scss';

const defaultProps = {
    className: '',
    title: 'Need help?',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function ContactPanel({ className, title }) {
    return (
        <>
            <div className={`m-contact-panel ${className}`}>
                <div className="l-grid">
                    <div className="m-contact-panel__container">
                        <div className="m-contact-panel__title-container">
                            <h2 className="m-contact-panel__title">{title}</h2>
                        </div>
                        <div className="m-contact-panel__content-container m-content">
                            <div className="m-content__content">
                                <p>
                                    We are available to assist you Monday to
                                    Friday from 09:00 to 5:30 via{' '}
                                    <a href="mailto:support@oxbury.com">
                                        support@oxbury.com
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

ContactPanel.propTypes = propTypes;
ContactPanel.defaultProps = defaultProps;

export default ContactPanel;
