import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconVimeo({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 16 16">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M15.992 4.16c-.07 1.54-1.16 3.645-3.26 6.317-2.176 2.794-4.019 4.19-5.525 4.19-.931 0-1.72-.85-2.365-2.554-.432-1.563-.86-3.127-1.292-4.686-.48-1.705-.993-2.557-1.542-2.557-.12 0-.537.249-1.255.747L0 4.657C.788 3.973 1.565 3.285 2.332 2.6c1.05-.9 1.84-1.372 2.366-1.421 1.242-.12 2.008.722 2.297 2.52.307 1.941.524 3.148.643 3.621.36 1.61.753 2.412 1.183 2.412.334 0 .837-.52 1.507-1.56.67-1.042 1.026-1.833 1.075-2.379.096-.9-.263-1.346-1.075-1.346-.383 0-.778.08-1.184.247.79-2.532 2.296-3.763 4.52-3.691 1.647.046 2.425 1.1 2.328 3.157" />
            </svg>
        </>
    );
}

IconVimeo.propTypes = propTypes;
IconVimeo.defaultProps = defaultProps;

export default IconVimeo;
