import React from 'react';
import PropTypes from 'prop-types';
import { BlogItem } from 'molecules';

import './BlogListing.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function BlogListing({
    className,
    listing,
}) {
    return (
        <>
            <div className={`o-blog-listing ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-blog-listing__container">
                        {listing.map(({ category, date, dateFormatted, description, image, link, title }, index) => (
                            <BlogItem
                                category={category}
                                className="o-blog-listing__item"
                                date={date}
                                dateFormatted={dateFormatted}
                                description={description?.text}
                                image={image}
                                key={`blog-item-${index}`}
                                link={link}
                                title={title?.text}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

BlogListing.propTypes = propTypes;
BlogListing.defaultProps = defaultProps;

export default BlogListing;
