import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'atoms';

import './Blockquote.scss';

const defaultProps = {
    authorName: '',
    authorLink: {},
    className: '',
    image: {},
    quote: '',
};

const propTypes = {
    authorName: PropTypes.string,
    authorLink: PropTypes.shape({
        _meta: PropTypes.object,
        url: PropTypes.string,
    }),
    className: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    quote: PropTypes.string,
};

function Blockquote({
    authorName,
    authorLink,
    className,
    image,
    quote,
}) {
    const supportsPicture = typeof window !== 'undefined' ? window.HTMLPictureElement : null;

    return (
        <>
            <blockquote className={`m-blockquote ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="m-blockquote__content-container">
                        {quote && (
                            <p className="m-blockquote__quote">{quote}</p>
                        )}

                        {authorName && (
                            <div className="m-blockquote__footer">
                                <p className="m-blockquote__author">
                                    {(authorName && authorLink?.link_type === 'Any') && (
                                        <span className="m-blockquote__author-name">{authorName}</span>
                                    )}
                                    {(authorName && authorLink?.link_type !== 'Any') && (
                                        <Link className="m-blockquote__author-link" to={authorLink}>{authorName}</Link>
                                    )}
                                </p>
                            </div>
                        )}
                    </div>
                    {image?.url && (
                        <div className="m-blockquote__media-container">
                            {supportsPicture && (
                                <Img
                                    alt={(image.alt) ? image.alt : ''}
                                    className="m-blockquote__image"
                                    fluid={{
                                        aspectRatio: image.dimensions.width / image.dimensions.height,
                                        src: `${image?.url}&w=50`,
                                        srcSet: `${image?.url}&w=375 375w, ${image?.url}&w=480 480w, ${image?.url}&w=768 768w, ${image?.url}&w=960 960w, ${image?.url}&w=1280 1280w, ${image?.url}&w=1920 1920w`,
                                        sizes: '(max-width: 1000px) 100vw, 1000px',
                                    }}
                                />
                            )}
                            {!supportsPicture && (
                                <img alt={(image.alt) ? image.alt : ''} className="m-blockquote__image" src={`${image?.url}&w=1920`} />
                            )}
                        </div>
                    )}
                </div>
            </blockquote>
        </>
    );
}

Blockquote.propTypes = propTypes;
Blockquote.defaultProps = defaultProps;

export default Blockquote;
