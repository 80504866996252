import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { Link } from 'atoms';

import './LinkListing.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function LinkListing({
    className,
    listing,
}) {
    const [ref, inView, entry] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    const inviewClassName = (!inView) ? '' : 'is-inview';

    return (
        <>
            <div className={`m-link-listing ${className} ${inviewClassName}`} ref={ref}>
                <div className="l-grid l-grid--container">
                    <div className="m-link-listing__container">
                        <ul className="m-link-listing__list">
                            {listing.map(({ quick_links_button_textbutton_background_colour, quick_links_button_text, quick_links_button_textbutton_url }, index) => (
                                <>
                                    {(quick_links_button_text?.text && quick_links_button_textbutton_url) && (
                                        <li className="m-link-listing__item" key={`{${index}${Math.random()}}`}>
                                            <Link className={`m-link-listing__link m-link-listing__link--${quick_links_button_textbutton_background_colour.toLowerCase()}`} to={quick_links_button_textbutton_url}>{quick_links_button_text?.text}</Link>
                                        </li>
                                    )}
                                </>
                            ))}
                        </ul>
                    </div>
                </div>

            </div>
        </>
    );
}

LinkListing.propTypes = propTypes;
LinkListing.defaultProps = defaultProps;

export default LinkListing;
