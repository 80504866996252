import React from 'react';
import PropTypes from 'prop-types';
import { KeyInfoItem } from 'molecules';

import './KeyInfoListing.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function KeyInfoListing({
    className,
    listing,
}) {
    return (
        <>
            <div className={`o-key-info-listing ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-key-info-listing__container">
                        {listing.map(({ key_info_description, key_info_title }, index) => (
                            <KeyInfoItem
                                className="o-key-info-listing__item"
                                description={key_info_description?.text}
                                key={`{${index}${Math.random()}}`}
                                term={key_info_title?.text}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

KeyInfoListing.propTypes = propTypes;
KeyInfoListing.defaultProps = defaultProps;

export default KeyInfoListing;
