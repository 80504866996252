import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'atoms';

import './InputField.scss';

const defaultProps = {
    className: '',
    disabled: false,
    id: '',
    label: '',
    name: '',
    onChange: () => {},
    placeholder: '',
    postText: '',
    preText: '',
    required: false,
    type: 'text',
    value: undefined,
};

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    postText: PropTypes.string,
    preText: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
};

const InputField = forwardRef(({
    className,
    disabled,
    id,
    label,
    name,
    onChange,
    placeholder,
    postText,
    preText,
    required,
    type,
    value,
    ...rest
}, ref) => {
    const postTextClassName = (postText) ? 'm-form-field__container--post-text' : '';
    const preTextClassName = (preText) ? 'm-form-field__container--pre-text' : '';

    return (
        <>
            <div className={`m-form-field ${className}`}>
                <Label
                    id={id}
                    label={label}
                    required={required}
                />
                {(!postText && !preText) && (
                    <Input
                        disabled={disabled}
                        id={id}
                        name={name}
                        onChange={onChange}
                        placeholder={placeholder}
                        ref={ref}
                        required={required}
                        type={type}
                        value={value}
                        {...rest}
                    />
                )}
                {(postText || preText) && (
                    <div className={`m-form-field__container ${postTextClassName} ${preTextClassName}`}>
                        {preText && (
                            <span className="m-form-field__pre-text" dangerouslySetInnerHTML={{ __html: preText }} />
                        )}
                        <Input
                            disabled={disabled}
                            id={id}
                            name={name}
                            onChange={onChange}
                            placeholder={placeholder}
                            ref={ref}
                            required={required}
                            type={type}
                            value={value}
                            {...rest}
                        />
                        {postText && (
                            <span className="m-form-field__post-text" dangerouslySetInnerHTML={{ __html: postText }} />
                        )}
                    </div>
                )}
            </div>
        </>
    );
});

InputField.propTypes = propTypes;
InputField.defaultProps = defaultProps;

export default InputField;
