import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from 'utility/linkResolver';

import './Notice.scss';

const defaultProps = {
    className: '',
    description: [],
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

function Notice({
    className,
    description,
    title,
}) {
    return (
        <>
            <div className={`m-notice ${className}`} role="alert">
                <div className="l-grid l-grid--container">
                    <div className="m-notice__container">
                        <div className="m-notice__icon-container">
                            <svg className="m-notice__icon" viewBox="0 0 106 46">
                                <path d="M47.148 45.575L69.282.9h-9.115L38.162 45.575h8.986zM65.121 45.575L87.126.9H78.14L56.274 45.575h8.847zM82.966 45.575L105.1.9h-8.858L74.108 45.575h8.858z" />
                                <path d="M.1 45.575V.9h51.21L29.313 45.575H.1zM19.37 18.506V7.754h-4.85v10.752c0 1.14.057 2.269.17 3.384.125 1.115.294 2.274.507 3.477h3.496c.213-1.203.376-2.362.489-3.477.125-1.115.188-2.243.188-3.384zm-5.32 12.632c-.15.364-.225.752-.225 1.166 0 .426.075.82.225 1.184.163.363.383.683.658.959.276.263.595.47.959.62.376.15.783.226 1.222.226.426 0 .82-.076 1.184-.226.376-.15.702-.357.977-.62.276-.276.495-.596.658-.959a2.86 2.86 0 00.245-1.184c0-.414-.082-.802-.245-1.166a2.901 2.901 0 00-.658-.958 2.876 2.876 0 00-.977-.64 2.862 2.862 0 00-1.184-.244 3.04 3.04 0 00-1.222.245c-.364.15-.683.363-.959.639a2.901 2.901 0 00-.658.958z" />
                            </svg>
                        </div>
                        <div className="m-noitce__content-container">
                            {title && (
                                <strong className="m-notice__title">{title}</strong>
                            )}
                            {description && (
                                <div className="m-notice__description">
                                    <RichText
                                        render={description}
                                        linkResolver={linkResolver}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

Notice.propTypes = propTypes;
Notice.defaultProps = defaultProps;

export default Notice;
