import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { cx } from 'utility';
import { Link } from 'atoms';

import './Button.scss';

const defaultProps = {
    className: '',
    children: null,
    to: '',
    type: 'button',
};

const propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    to: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

/* eslint-disable react/button-has-type */
const Button = forwardRef(({
    className,
    children,
    to,
    type,
    ...rest
}, ref) => {
    const classes = cx('a-button', className);
    const isLink = to !== '';

    if (isLink) {
        return (
            <Link className={classes} ref={ref} to={to} {...rest}>
                {children}
            </Link>
        );
    }

    return (
        <button className={classes} ref={ref} type={type} {...rest}>
            {children}
        </button>
    );
});
/* eslint-enable react/button-has-type */

Button.displayName = 'Button';
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
