import React from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from 'atoms';

import './Pagination.scss';

const defaultProps = {
    className: '',
    label: 'Pagination',
    nextPageText: 'Older posts',
    prevPageText: 'Newer posts',
};

const propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    nextPageText: PropTypes.string,
    nextPageUrl: PropTypes.string.isRequired,
    prevPageText: PropTypes.string,
    prevPageUrl: PropTypes.string.isRequired,
};

function Pagination({
    className,
    label,
    nextPageText,
    nextPageUrl,
    prevPageText,
    prevPageUrl,
}) {
    const nextPageUrlPath = {
        link_type: 'Document',
        type: 'blog_pagination',
        uid: nextPageUrl.substring(1)
    };
    const prevPageUrlPath = {
        link_type: 'Document',
        type: 'blog_pagination',
        uid: prevPageUrl.substring(1)
    };

    return (
        <>
            <nav className={`m-pagination ${className}`} aria-label={label}>
                <div className="m-pagination__container">
                    {prevPageUrlPath?.uid && (
                        <Link className="a-button a-button--large m-pagination__button" to={prevPageUrlPath}>
                            <span className="m-pagination__button-text">{prevPageText}</span>
                        </Link>
                    )}
                    {!prevPageUrlPath?.uid && (
                        <Button className="a-button a-button--large m-pagination__button" disabled={true} type="button">
                            <span className="m-pagination__button-text">{prevPageText}</span>
                        </Button>
                    )}
                    {nextPageUrlPath?.uid && (
                        <Link className="a-button a-button--large m-pagination__button" to={nextPageUrlPath}>
                            <span className="m-pagination__button-text">{nextPageText}</span>
                        </Link>
                    )}
                    {!nextPageUrlPath?.uid && (
                        <Button className="a-button a-button--large m-pagination__button" disabled={true} type="button">
                            <span className="m-pagination__button-text">{nextPageText}</span>
                        </Button>
                    )}
                </div>
            </nav>
        </>
    );
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
