import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconUser({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 16 16">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M8 8a4 4 0 100-8 4 4 0 000 8zm4.233-.571a5.416 5.416 0 01-1.883 1.526 5.353 5.353 0 01-4.705 0A5.416 5.416 0 013.76 7.43a8.046 8.046 0 00-2.757 2.936A8.16 8.16 0 000 14.285V16h16v-1.714a8.16 8.16 0 00-1.007-3.922 8.046 8.046 0 00-2.76-2.935z" />
            </svg>
        </>
    );
}

IconUser.propTypes = propTypes;
IconUser.defaultProps = defaultProps;

export default IconUser;
