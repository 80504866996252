import React from 'react';
import PropTypes from 'prop-types';
import { Content } from 'molecules';
import { KeyInfoListing } from 'organisms';

import './KeyInfoListingContent.scss';

const defaultProps = {
    className: '',
    content: [],
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    listing: PropTypes.arrayOf(PropTypes.object),
};

function KeyInfoListingContent({
    className,
    content,
    listing,
}) {
    return (
        <>
            <div className={`o-key-info-listing-content ${className}`}>
                <div className="l-grid l-grid--container">
                    {listing && (
                        <KeyInfoListing
                            className="o-key-info-listing-content__listing"
                            listing={listing}
                        />
                    )}
                    {content && (
                        <Content
                            className="o-key-info-listing-content__content"
                            content={content}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

KeyInfoListingContent.propTypes = propTypes;
KeyInfoListingContent.defaultProps = defaultProps;

export default KeyInfoListingContent;
