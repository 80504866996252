import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from 'utility';

import './Content.scss';

const defaultProps = {
    className: '',
    content: [],
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    tableColour: PropTypes.string,
};

function Content({
    className,
    content,
    tableColour,
}) {
    return (
        <>
            <div className={`m-content ${className} t-${tableColour?.toLowerCase()}`}>
                {content && (
                    <div className="m-content__content">
                        <RichText
                            htmlSerializer={htmlSerializer}
                            render={content}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
