import React from 'react';
import PropTypes from 'prop-types';
import { Content, SectionHeader } from 'molecules';

import './ContentHeader.scss';

const defaultProps = {
    className: '',
    content: [],
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    tableColour: PropTypes.string,
};

function ContentHeader({
    className,
    content,
    title,
    tableColour,
}) {
    return (
        <>
            <div className={`o-content-header ${className}`}>
                <div className="l-grid l-grid--container">
                    {title && (
                        <SectionHeader className="o-content-header__title" title={title} tableColour={tableColour} />
                    )}
                    {content && (
                        <Content className="o-content-header__content" content={content} tableColour={tableColour} />
                    )}
                </div>
            </div>
        </>
    );
}

ContentHeader.propTypes = propTypes;
ContentHeader.defaultProps = defaultProps;

export default ContentHeader;
