import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { cx } from 'utility';
import { RichText } from 'prismic-reactjs';
import { Button } from 'atoms';
import { MastheadReviewCard, SocialShare } from 'molecules';
import { useNetworkState } from 'react-use';

import './Masthead.scss';

const defaultProps = {
    className: '',
    ctaButtonText: '',
    ctaButtonUrl: {},
    ctaColour: '',
    ctaTitle: [],
    description: '',
    headingSize: 'h1',
    image: {},
    isFeefoReview: false,
    reviewText: [],
    reviewTitle: [],
    pageUrl: '',
    socialShare: false,
    strapline: '',
    title: '',
    video: {},
    videoAutoplay: true,
};

const propTypes = {
    className: PropTypes.string,
    ctaButtonText: PropTypes.string,
    ctaButtonUrl: PropTypes.object,
    ctaColour: PropTypes.string,
    ctaTitle: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.string,
    headingSize: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    isFeefoReview: PropTypes.bool,
    pageUrl: PropTypes.string,
    reviewText: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
            spans: PropTypes.array,
        })
    ),
    reviewTitle: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
            spans: PropTypes.array,
        })
    ),
    socialShare: PropTypes.bool,
    strapline: PropTypes.string,
    title: PropTypes.string,
    video: PropTypes.object,
    videoAutoplay: PropTypes.bool,
};

function Masthead({
    className,
    ctaButtonText,
    ctaButtonUrl,
    ctaColour,
    ctaTitle,
    description,
    headingSize,
    image,
    isFeefoReview,
    pageUrl,
    reviewText,
    reviewTitle,
    socialShare,
    strapline,
    title,
    video,
    videoAutoplay,
}) {
    let supportsPicture = true;
    if (typeof window !== 'undefined') {
        supportsPicture = window?.HTMLPictureElement ? true : null;
    }
    const ctaClassModifier = ((ctaButtonText && ctaButtonUrl) || ctaTitle?.[0]) ? 'm-masthead--has-cta' : '';
    const noMediaModifier = (!image || !video) ? 'm-masthead--no-media' : '';
    const classes = cx(ctaClassModifier, noMediaModifier, className);

    const [loadVideo, setLoadVideo] = useState(false);
    const supportsConnection = typeof window !== 'undefined' ? navigator.connection || navigator.mozConnection || navigator.webkitConnection : null;
    const networkState = supportsConnection ? useNetworkState() : '';
    // Only try and render the video if it hasn’t already been loaded or it’s not at build time
    if (!loadVideo && typeof window !== 'undefined') {
        // If the browser supports network detection and the network speed is fast enough, load the video or if it doesn’t support network detection, load the video
        if (networkState) {
            if (networkState.effectiveType === '3g' || networkState.effectiveType === '4g') {
                setLoadVideo(true);
            }
        } else {
            setLoadVideo(true);
        }
    }

    return (
        <>
            <div className={`m-masthead ${classes}`}>
                <div className="l-grid l-grid--container">
                    {(strapline || title || description) && (
                    <div className="m-masthead__content-container">
                        {(strapline || title || description) && (
                            <div className="m-masthead__content-wrapper">
                                {strapline && (
                                    <span className="m-masthead__strapline">
                                        <span className="m-masthead__strapline-inner">{strapline}</span>
                                    </span>
                                )}
                                {(title && headingSize === 'h1') && (
                                    <h1 className="m-masthead__title">{title}</h1>
                                )}
                                {(title && headingSize === 'h2') && (
                                    <h2 className="m-masthead__title">{title}</h2>
                                )}
                                {description && (
                                    <p className="m-masthead__description">{description}</p>
                                )}
                                {socialShare && (
                                    <SocialShare className="m-masthead__social-share" pageUrl={pageUrl} />
                                )}
                            </div>
                        )}
                        {isFeefoReview
                            ? 
                                <MastheadReviewCard
                                    ctaColour={ctaColour}
                                    reviewText={reviewText}
                                    reviewTitle={reviewTitle}
                                />
                            : ((ctaButtonText && ctaButtonUrl) || ctaTitle?.[0])
                            ?
                                <div className={`m-masthead__cta-container m-masthead__cta-container--${ctaColour.toLowerCase()}`}>
                                    <div className="m-masthead__cta-wrapper">
                                        {ctaTitle?.length > 0 && (
                                            <div className="m-masthead__cta-title">
                                                <RichText
                                                    render={ctaTitle}
                                                />
                                            </div>
                                        )}
                                        {(ctaButtonText && ctaButtonUrl?.link_type !== 'Any') && (
                                            <Button className={`m-masthead__cta-button a-button--fill a-button--large a-button--${ctaColour.toLowerCase()}`} to={ctaButtonUrl}>{ctaButtonText}</Button>
                                        )}
                                    </div>
                                </div>
                            : <></>
                        }
                    </div>
                    )}
                    {(image?.url || video?.url) && (
                        <div className="m-masthead__media-container">
                            {image?.url && (
                                <>
                                    {supportsPicture && (
                                        <Img
                                            alt={(image.alt) ? image.alt : ''}
                                            className="m-masthead__image"
                                            fluid={{
                                                aspectRatio: image?.dimensions?.width / image?.dimensions?.height,
                                                src: `${image?.url}&w=50`,
                                                srcSet: `${image?.url}&w=375 375w, ${image?.url}&w=480 480w, ${image?.url}&w=768 768w, ${image?.url}&w=960 960w, ${image?.url}&w=1280 1280w, ${image?.url}&w=1920 1920w`,
                                                sizes: '(max-width: 1000px) 100vw, 1000px',
                                            }}
                                        />
                                    )}
                                    {!supportsPicture && (
                                        <img alt={(image.alt) ? image.alt : ''} className="m-masthead__image" src={`${image?.url}&w=1920`} />
                                    )}
                                </>
                            )}
                            {(loadVideo && video?.url) && (
                                <video autoPlay={videoAutoplay} loop={true} muted={true} playsInline={true} className="m-masthead__video" src={video?.url} />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

Masthead.propTypes = propTypes;
Masthead.defaultProps = defaultProps;

export default Masthead;
