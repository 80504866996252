import React from 'react';
import PropTypes from 'prop-types';

import './SectionHeader.scss';

const defaultProps = {
    className: '',
    icon: {
        alt: '',
        url: '',
    },
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    icon: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    title: PropTypes.string,
};

function SectionHeader({
    className,
    icon,
    title,
    tableColour,
}) {
    return (
        <>
            <div className={`${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="m-section-header__container">
                        <div className="m-section-header__title-container">
                            {icon?.url && (
                                <img alt={(icon.alt) ? icon.alt : ''} className="m-section-header__icon" loading="lazy" src={icon?.url} />
                            )}
                            {title && (
                                <h2 className="m-section-header__title">{title}</h2>
                            )}
                        </div>
                        <div className={`m-section-header__border ${tableColour ? `${tableColour.toLowerCase()}-background` : ''}`}>
                            <svg className={`m-section-header__border-descender ${tableColour ? `${tableColour.toLowerCase()}-fill` : ''}`} viewBox="0 0 16 16">
                                <path d="M3.65 16H7.9L16 0H0v4h9.6L3.65 16z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
