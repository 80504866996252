import React from 'react';
import PropTypes from 'prop-types';
import { FeatureBlockItem } from 'molecules';

import './FeatureBlockListing.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function FeatureBlockListing({
    className,
    listing,
}) {
    return (
        <>
            <div className={`o-feature-block-listing ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-feature-block-listing__container">
                        {listing.map(({ description, icon, link, link_text, title }, index) => (
                            <FeatureBlockItem
                                className="o-feature-block-listing__item"
                                description={description?.text}
                                icon={icon}
                                key={`{${index}${Math.random()}}`}
                                link={link}
                                linkText={link_text?.text}
                                title={title?.text}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

FeatureBlockListing.propTypes = propTypes;
FeatureBlockListing.defaultProps = defaultProps;

export default FeatureBlockListing;
