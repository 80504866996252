import React from 'react';
import PropTypes from 'prop-types';

import './FeefoReviewCard.scss';

const defaultProps = {
    product: "",
    stars: {},
    text: "",
    title: "",
};

const propTypes = {
    product: PropTypes.string,
    stars: PropTypes.shape({ alt: PropTypes.string, url: PropTypes.string }),
    text: PropTypes.string,
    title: PropTypes.string,
};

function FeefoReviewCard({ product, stars, text, title }) {
    return (
        <div className="m-feefo-review-card__container">
            <h3 className="m-feefo-review-card__title">{title}</h3>
            <i className="m-feefo-review-card__text">
                <b className="m-feefo-review-card__quote">"</b>
                {text}
                <b className="m-feefo-review-card__quote">"</b>
            </i>
            <img
                className="m-feefo-review-card__stars"
                alt={stars.alt}
                src={stars.url}
            />
            <b className="m-feefo-review-card__product">{product}</b>
        </div>
    );
}

FeefoReviewCard.propTypes = propTypes;
FeefoReviewCard.defaultProps = defaultProps;

export default FeefoReviewCard;
