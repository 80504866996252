import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import './MastheadReviewCard.scss';

const defaultProps = {
    ctaColour: "",
    reviewText: [],
    reviewTitle: [],
};

const propTypes = {
    ctaColour: PropTypes.string,
    reviewText: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
            spans: PropTypes.array,
        })
    ),
    reviewTitle: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
            spans: PropTypes.array,
        })
    ),
};

function MastheadReviewCard({ ctaColour, reviewText, reviewTitle }) {
    return (
        <div
            className={`m-masthead-review-card__container m-masthead-review-card__container--${ctaColour.toLowerCase()}`}
        >
            <div className="m-masthead-review-card__wrapper">
                <div className="m-masthead-review-card__feefo-icons-wrapper">
                    <img
                        className="m-masthead-review-card__feefo-logo"
                        alt="Feefo logo"
                        src="/images/feefo-logo.png"
                    />
                    <img alt="Stars rating" src="/images/stars.svg" />
                </div>
                {reviewTitle?.length > 0 && (
                    <div className="m-masthead-review-card__review-title">
                        <RichText render={reviewTitle} />
                    </div>
                )}
                {reviewText?.length > 0 && (
                    <div className="m-masthead-review-card__review-text">
                        <RichText render={reviewText} />
                    </div>
                )}
            </div>
        </div>
    );
}

MastheadReviewCard.propTypes = propTypes;
MastheadReviewCard.defaultProps = defaultProps;

export default MastheadReviewCard;
