import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { Link } from 'atoms';

import './Download.scss';

const defaultProps = {
    className: '',
    fileName: '',
    link: {
        size: 0,
        url: '',
    },
    linkText: '',
    link2: {
        size: 0,
        url: '',
    },
    link2Text: '',
};

const propTypes = {
    className: PropTypes.string,
    fileName: PropTypes.string,
    link: PropTypes.shape({
        size: PropTypes.number,
        url: PropTypes.string,
    }),
    linkText: PropTypes.string,
    link2: PropTypes.shape({
        size: PropTypes.number,
        url: PropTypes.string,
    }),
    link2Text: PropTypes.string,
};

function Download({
    className,
    fileName,
    link,
    linkText,
    link2,
    link2Text,
}) {
    const [ref, inView, entry] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    const inviewClassName = (!inView) ? '' : 'is-inview';
    const fileExtentions = (link?.url) ? link?.url.slice(link?.url.length - 3).toUpperCase() : '';
    const fileSize = bytesToSize(link?.size);
    const file2Extentions = (link2?.url) ? link2?.url.slice(link2?.url.length - 3).toUpperCase() : '';
    const file2Size = bytesToSize(link2?.size);

    function bytesToSize(bytes) {
        var sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
        if (bytes == 0) return 'n/a';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + ' ' + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    };

    return (
        <>
            <div className={`m-download ${className} ${inviewClassName}`} itemScope itemType="http://schema.org/DigitalDocument" ref={ref}>
                <div className="l-grid l-grid--container">
                    <div className="m-download__container">
                        <div className="m-download__wrapper">
                            {fileName && (
                                <span className="m-download__file-name">
                                    {fileName}
                                    {(fileExtentions && fileSize) && (!file2Extentions || !file2Size) && (
                                        <span className="m-download__extension">({fileExtentions} {fileSize})</span>
                                    )}
                                </span>
                            )}
                            {(linkText && link?.link_type !== 'Any') && (
                                <Link className="m-download__button a-button a-button--fill a-button--large" download itemProp="url" to={link}>
                                <span className="m-download__button-text">{linkText}</span>
                                    {(fileExtentions && fileSize) && (file2Extentions && file2Size) && (
                                        <span className="m-download__extension">({fileExtentions} {fileSize})</span>
                                    )}
                                </Link>
                            )}
                            {(link2Text && link2?.link_type !== 'Any') && (
                                <Link className="m-download__button a-button a-button--fill a-button--large" download itemProp="url" to={link2}>
                                    <span className="m-download__button-text">{link2Text}</span>
                                    {(fileExtentions && fileSize) && (file2Extentions && file2Size) && (
                                        <span className="m-download__extension">({file2Extentions} {file2Size})</span>
                                    )}
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

Download.propTypes = propTypes;
Download.defaultProps = defaultProps;

export default Download;
