import React from 'react';
import PropTypes from 'prop-types';
import { IconChevronDown } from 'atoms';

import './Select.scss';

const defaultProps = {
    className: '',
    disabled: false,
    id: '',
    name: '',
    onChange: () => {},
    options: [],
    presetOption: false,
    required: false,
    touched: false,
};

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.string),
    presetOption: PropTypes.bool,
    required: PropTypes.bool,
    toched: PropTypes.bool,
};

function Select({
    className,
    disabled,
    id,
    name,
    onChange,
    options,
    presetOption,
    required,
    touched,
    ...rest
}) {
    return (
        <>
            <div className={`a-select ${className}`}>
                <select className="a-select__select" disabled={disabled} id={id} name={name} onChange={onChange} required={required} {...rest}>
                    {!presetOption && (
                        <option disabled={touched} value="">Please select</option>
                    )}
                    {options.map(option => (
                        <option key={option} value={option.trim()}>{option.trim()}</option>
                    ))}
                </select>
                <IconChevronDown className="a-select__arrow-icon" />
            </div>
        </>
    );
}

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
