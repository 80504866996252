import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { cx } from 'utility';
import { Link } from 'atoms';

import './SavingsCTA.scss';

const defaultProps = {
    buttonBackgroundColour: '',
    buttonText: '',
    buttonUrl: {},
    className: '',
    interestRate: '',
    interestRateLabel: '',
    max: 0,
    min: 0,
    period: '',
    title: '',
};

const propTypes = {
    buttonBackgroundColour: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.shape({
        _meta: PropTypes.object,
        url: PropTypes.string,
    }),
    className: PropTypes.string,
    interestRate: PropTypes.string,
    interestRateLabel: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    period: PropTypes.string,
    title: PropTypes.string,
};

function SavingsCTA({
    buttonBackgroundColour,
    buttonText,
    buttonUrl,
    className,
    interestRate,
    interestRateLabel,
    max,
    min,
    period,
    title,
}) {
    const [ref, inView, entry] = useInView({
        threshold: 0.75,
        triggerOnce: true,
    });
    const inviewClassName = (!inView) ? '' : 'is-inview';
    const classes = cx(`m-savings-cta--${buttonBackgroundColour.toLowerCase()}`, inviewClassName, className);

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <>
            <div className={`m-savings-cta ${classes}`} ref={ref}>
                <div className="l-grid l-grid--container">
                    <div className="m-savings-cta__container">
                        <div className="m-savings-cta__wrapper">
                            {title && (
                                <h4 className="m-savings-cta__title">{title}</h4>
                            )}
                            {(interestRate || interestRateLabel) && (
                                <div className="m-savings-cta__interest-rate-container">
                                    {interestRate && (
                                        <span className="m-savings-cta__interest-rate">{interestRate}</span>
                                    )}
                                    {interestRateLabel && (
                                        <span className="m-savings-cta__interest-rate-label">{interestRateLabel}</span>
                                    )}
                                </div>
                            )}
                            {period && (
                                <span className="m-savings-cta__period">{period}</span>
                            )}
                            {(min || max) && (
                                <dl className="m-savings-cta__min-max-list">
                                    {min && (
                                        <div className="m-savings-cta__min-max-item">
                                            <dt className="m-savings-cta__min-max-term">Min:</dt>
                                            <dd className="m-savings-cta__min-max-description">{numberWithCommas(min)}</dd>
                                        </div>
                                    )}
                                    {max && (
                                        <div className="m-savings-cta__min-max-item">
                                            <dt className="m-savings-cta__min-max-term">Max:</dt>
                                            <dd className="m-savings-cta__min-max-description">{numberWithCommas(max)}</dd>
                                        </div>
                                    )}
                                </dl>
                            )}
                            {(buttonText && buttonUrl?.link_type !== 'Any') && (
                                <Link className={`m-savings-cta__button a-button a-button--fill a-button--large a-button--${buttonBackgroundColour.toLowerCase()}`} to={buttonUrl}>{buttonText}</Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

SavingsCTA.propTypes = propTypes;
SavingsCTA.defaultProps = defaultProps;

export default SavingsCTA;
