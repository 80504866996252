import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconTwitter({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 16 16">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M16 2.578a6.412 6.412 0 0 1-1.885.53 3.359 3.359 0 0 0 1.444-1.861 6.465 6.465 0 0 1-2.086.815A3.237 3.237 0 0 0 11.077 1C9.265 1 7.796 2.507 7.796 4.366c0 .264.028.52.084.766-2.728-.14-5.146-1.479-6.766-3.518A3.427 3.427 0 0 0 .67 3.308c0 1.168.579 2.198 1.46 2.802a3.223 3.223 0 0 1-1.488-.42v.041c0 1.632 1.132 2.993 2.635 3.3a3.189 3.189 0 0 1-1.483.059c.418 1.337 1.63 2.31 3.066 2.337A6.482 6.482 0 0 1 0 12.822a9.14 9.14 0 0 0 5.032 1.511c6.038 0 9.34-5.128 9.34-9.576 0-.147-.003-.293-.009-.436A6.737 6.737 0 0 0 16 2.578" />
            </svg>
        </>
    );
}

IconTwitter.propTypes = propTypes;
IconTwitter.defaultProps = defaultProps;

export default IconTwitter;
