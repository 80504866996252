import React from 'react';
import PropTypes from 'prop-types';

import './Video.scss';

const defaultProps = {
    caption: '',
    className: '',
    video: {
        html: '',
    },
};

const propTypes = {
    caption: PropTypes.string,
    className: PropTypes.string,
    video: PropTypes.shape({
        html: PropTypes.string,
    }),
};

function Video({ caption, className, video }) {
    var videoIframe = video?.html?.replace(
        '<iframe',
        '<iframe data-cookieconsent="marketing"'
    );

    return (
        <>
            <div className={`a-video ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="a-video__container">
                        {video?.html && (
                            <div
                                className="a-video__wrapper"
                                dangerouslySetInnerHTML={{
                                    __html: videoIframe,
                                }}
                            />
                        )}
                        {caption && (
                            <div className="a-video__caption">{caption}</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;
