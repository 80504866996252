import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { Checkbox, Input, Label, Message, Select } from 'atoms';
import { Content, InputField, SectionHeader, SelectField, TextareaField } from 'molecules';
import validation from 'utility/validation';

import './ContactForm.scss';

const defaultProps = {
    callTimeLabel: '',
    className: '',
    content: [],
    emailLabel: '',
    farmSizeLabel: '',
    farmTypeLabel: '',
    farmTypeOptions: '',
    farmTypeZenSellFieldName: '',
    firstNameLabel: '',
    footerContent: [],
    formMessage: '',
    formMessageType: '',
    landFarmedLabel: '',
    landFarmedOptions: '',
    lastNameLabel: '',
    loanValueLabel: '',
    loanValueOptions: '',
    messageLabel: '',
    pathname: '',
    phoneLabel: '',
    postcodeLabel: '',
    product: '',
    submitFormUrl: '',
    submitText: '',
    subscribeEmailLabel: '',
    subscribeNoneLabel: '',
    subscribePostLabel: '',
    subscribeSMSLabel: '',
    subscribeTelephoneLabel: '',
    title: '',
};

const propTypes = {
    callTimeLabel: PropTypes.string,
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    emailLabel: PropTypes.string,
    farmTypeLabel: PropTypes.string,
    farmTypeOptions: PropTypes.string,
    farmTypeZenSellFieldName: PropTypes.string,
    firstNameLabel: PropTypes.string,
    footerContent: PropTypes.arrayOf(PropTypes.object),
    formMessage: PropTypes.string,
    formMessageType: PropTypes.string,
    landFarmedLabel: PropTypes.string,
    landFarmedOptions: PropTypes.string,
    lastNameLabel: PropTypes.string,
    loanValueLabel: PropTypes.string,
    loanValueOptions: PropTypes.string,
    messageLabel: PropTypes.string,
    pathname: PropTypes.string,
    phoneLabel: PropTypes.string,
    postcodeLabel: PropTypes.string,
    product: PropTypes.string,
    submitText: PropTypes.string,
    subscribeEmailLabel: PropTypes.string,
    subscribeNoneLabel: PropTypes.string,
    subscribePostLabel: PropTypes.string,
    subscribeSMSLabel: PropTypes.string,
    subscribeTelephoneLabel: PropTypes.string,
    title: PropTypes.string,
};

function ContactForm({
    callTimeLabel,
    className,
    content,
    emailLabel,
    farmTypeLabel,
    farmTypeOptions,
    farmTypeZenSellFieldName,
    footerContent,
    formMessage,
    formMessageType,
    firstNameLabel,
    landFarmedLabel,
    landFarmedOptions,
    lastNameLabel,
    loanValueLabel,
    loanValueOptions,
    messageLabel,
    pathname,
    phoneLabel,
    postcodeLabel,
    product,
    submitText,
    subscribeEmailLabel,
    subscribeNoneLabel,
    subscribePostLabel,
    subscribeSMSLabel,
    subscribeTelephoneLabel,
    title,
}) {
    const recaptchaRef = useRef();
    const farmTypeOptionArray = farmTypeOptions.split(',');
    const landFarmedOptionArray = landFarmedOptions.split(',');
    const loanValueOptionArray = loanValueOptions.split(',');

    let productValue = (product) ? [product] : [pathname];
    // If the product field isn’t set in Prismic, check if the page url matches any of the following URLs and set it this way
    if (!product) {
        if (pathname === '/farm-credit' || pathname === '/farm-credit/' || pathname === '/farm-credit1' || pathname === '/farm-credit1/') {
            productValue = ['Oxbury Farm Credit'];
        } else if (pathname === '/farm-loans' || pathname === '/farm-loans/') {
            productValue = ['Oxbury Term Loan'];
        } else if (pathname === '/savings' || pathname === '/savings/') {
            productValue = ['Oxbury Savings'];
        } else if (pathname === '/oxbury-forest-saver' || pathname === '/oxbury-forest-saver/') {
            productValue = ['OFS'];
        } else {
            productValue = [pathname];
        }
    }

    const [state, setState] = useState(() => {
        const initialErrors = {};
        const initialValues = {};
        const initialTouched = {};
        if (callTimeLabel) {
            initialErrors.callTime = true;
            initialValues.callTime =  '';
        }
        if (emailLabel) {
            initialErrors.email = true;
            initialValues.email =  '';
        }
        if (farmTypeLabel) {
            initialErrors.farmType =  true;
            initialValues.farmType =  '';
            initialTouched.farmType = false;
        }
        if (firstNameLabel) {
            initialErrors.firstName = true;
            initialValues.firstName = '';
        }
        if (landFarmedLabel) {
            initialErrors.landFarmed =  true;
            initialValues.landFarmed =  '';
            initialErrors.landFarmedUnit =  false;
            initialValues.landFarmedUnit =  landFarmedOptionArray[0];
        }
        if (lastNameLabel) {
            initialErrors.lastName =  true;
            initialValues.lastName =  '';
        }
        if (loanValueLabel) {
            initialErrors.loanValue =  true;
            initialValues.loanValue =  '';
            initialTouched.loanValue = false;
        }
        if (messageLabel) {
            initialErrors.message =  false;
            initialValues.message =  '';
        }
        if (phoneLabel) {
            initialErrors.phone =  true;
            initialValues.phone =  '';
        }
        if (postcodeLabel) {
            initialErrors.postcode =  true;
            initialValues.postcode =  '';
        }
        if (subscribeTelephoneLabel) {
            initialErrors.subscribeTelephone =  true;
            initialValues.subscribeTelephone =  false;
        }
        if (subscribeEmailLabel) {
            initialErrors.subscribeEmail =  true;
            initialValues.subscribeEmail =  false;
        }
        if (subscribePostLabel) {
            initialErrors.subscribePost =  true;
            initialValues.subscribePost =  false;
        }
        if (subscribeSMSLabel) {
            initialErrors.subscribeSMS =  true;
            initialValues.subscribeSMS =  false;
        }
        if (subscribeNoneLabel) {
            initialErrors.subscribeNone =  true;
            initialValues.subscribeNone =  false;
        }
        if (subscribeTelephoneLabel || subscribeEmailLabel || subscribePostLabel || subscribeSMSLabel || subscribeNoneLabel) {
            initialValues.marketingPreference =  [];
        }
        return {
            disableSubmit: true,
            formMessage: formMessage,
            formMessageType: formMessageType,
            recaptchaValid: false,
            errors: initialErrors,
            values: initialValues,
            touched: initialTouched,
            allowMarketingPreferenceErrors: false,
        }
    });

    function validateField(field) {
        let error;
        if (field.type === 'email') {
            error = validation.validateEmail(field.value) ? false : true;
        } else {
            error = field.checkValidity() ? false : true;
        }

        setState((previousState) => {
            return {
                ...previousState,
                errors: {
                    ...previousState.errors,
                    [field.name]: error,
                }
            }
        });
    }

    function handleFieldChange(event) {
        event.persist();

        setState((previousState) => {
            return {
                ...previousState,
                values: {
                    ...previousState.values,
                    [event.target.name]: event.target.value,
                },
                touched: {
                    ...previousState.touched,
                    [event.target.name]: true,
                }
            }
        });

        validateField(event.target);
    }

    function atLeastOneCheckboxIsChecked() {
        const valuesToCheck = [
            state.values.subscribeEmail,
            state.values.subscribeNone,
            state.values.subscribePost,
            state.values.subscribeSMS,
            state.values.subscribeTelephone
        ];

        return valuesToCheck.some((result) => result === true);
    }

    function validateCheckboxes() {
        const error = !atLeastOneCheckboxIsChecked();

        setState((previousState) => {
            return {
                ...previousState,
                errors: {
                    ...previousState.errors,
                    subscribeEmail: error,
                    subscribeNone: error,
                    subscribePost: error,
                    subscribeSMS: error,
                    subscribeTelephone: error,
                }
            }
        });
    }

    function handleCheckboxChange(event) {
        event.persist();

        setState((previousState) => {
            const newState = {
                ...previousState,
                values: {
                    ...previousState.values,
                    [event.target.name]: !state.values[event.target.name],
                }
            }
            if (event.target.checked && previousState.values.subscribeNone !== undefined) {
                newState.values.subscribeNone = false;
            }
            return newState;
        });
    }

    function handleNoneCheckboxChange(event) {
        event.persist();

        setState((previousState) => {
            const newState = {
                ...previousState,
                values: {
                    ...previousState.values,
                    subscribeNone: !state.values.subscribeNone,
                }
            }

            if (event.target.checked) {
                const checkboxesToClear = [
                    'subscribeEmail',
                    'subscribePost',
                    'subscribeSMS',
                    'subscribeTelephone',
                ]

                checkboxesToClear.forEach((checkbox) => {
                    if (previousState.values[checkbox] !== undefined) {
                        newState.values[checkbox] = false;
                    }
                })
            }

            return newState;
        });
    }

    function handleRecaptchaChange(value) {
        if (value) {
            setState((previousState) => {
                const newState = {
                    ...previousState,
                    recaptchaValid: true,
                }
                if (!atLeastOneCheckboxIsChecked()) {
                    newState.formMessageType = 'error';
                    newState.formMessage = 'Please select at least one of the marketing preferences.';
                    newState.allowMarketingPreferenceErrors = true;
                }
                return newState;
            });
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        // Disable submit button whilst posting data
        setState((previousState) => {
            return {
                ...previousState,
                disableSubmit: true,
            }
        });

        // Get the recaptcha value
        const recaptchaValue = recaptchaRef.current.getValue();


        // Create an object of data to be sent to Zen Sell
        let formData = {data: {custom_fields: {},address:{}}}
        if (state.values.callTime) { formData.data.custom_fields[process.env.GATSBY_ZEN_SELL_CALL_TIME_ID] = state.values.callTime; }
        if (state.values.email) { formData.data[process.env.GATSBY_ZEN_SELL_EMAIL_ID] = state.values.email; }
        if (state.values.farmType) { formData.data.custom_fields[(farmTypeZenSellFieldName) ? farmTypeZenSellFieldName : process.env.GATSBY_ZEN_SELL_FARM_TYPE_ID] = state.values.farmType; }
        if (state.values.firstName) { formData.data[process.env.GATSBY_ZEN_SELL_FIRST_NAME_ID] = state.values.firstName; }
        if (state.values.landFarmed) { formData.data.custom_fields[process.env.GATSBY_ZEN_SELL_LAND_FARMED_ACRES_ID] = (state.values.landFarmedUnit.trim() === "Acres") ? Math.round(state.values.landFarmed).toString() : (Math.round(state.values.landFarmed*2.471)).toString(); }
        if (state.values.landFarmed) { formData.data.custom_fields[process.env.GATSBY_ZEN_SELL_LAND_FARMED_HECTARES_ID] = (state.values.landFarmedUnit.trim() === "Hectares") ? Math.round(state.values.landFarmed).toString() : (Math.round(state.values.landFarmed/2.471)).toString(); }
        if (state.values.lastName) { formData.data[process.env.GATSBY_ZEN_SELL_LAST_NAME_ID] = state.values.lastName; }
        if (state.values.loanValue) { formData.data.custom_fields[process.env.GATSBY_ZEN_SELL_LOAN_VALUE_ID] = state.values.loanValue; }
        if (state.values.message) { formData.data.custom_fields[process.env.GATSBY_ZEN_SELL_MESSAGE_ID] = state.values.message; }
        if (state.values.phone) { formData.data[process.env.GATSBY_ZEN_SELL_PHONE_ID] = state.values.phone; }
        if (state.values.postcode) { formData.data.address[process.env.GATSBY_ZEN_SELL_POST_CODE_ID] = state.values.postcode; }
        if (state.values.marketingPreference) { formData.data.custom_fields[process.env.GATSBY_ZEN_SELL_MARKETING_PREFERENCE] = state.values.marketingPreference; }
        formData.data.custom_fields[process.env.GATSBY_ZEN_SELL_PRODUCT_ID] = productValue;

        // Post form data to Zen Sell
        fetch(process.env.GATSBY_ZEN_SELL_FORM_ENDPOINT, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Recaptcha-Token': recaptchaValue,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        }).then(function(response) {
            // Display a success message
            setState((previousState) => {
                return {
                    ...previousState,
                    disableSubmit: false,
                    formMessage: 'Your form has been sent successfully, someone will get in touch with you soon.',
                    formMessageType: 'success'
                }
            });
        }).catch(function(error) {
            // Display an error message
            setState((previousState) => {
                return {
                    ...previousState,
                    disableSubmit: false,
                    formMessage: `Oh no, something went wrong, please try again.`,
                    formMessageType: 'error'
                }
            });
        });
    }

    useEffect(function() {
        let marketingPreference = [];

        if (state.values.subscribeTelephone) {
            marketingPreference.push('Telephone');
        }

        if (state.values.subscribeEmail) {
            marketingPreference.push('Email');
        }

        if (state.values.subscribePost) {
            marketingPreference.push('Post');
        }

        if (state.values.subscribeSMS) {
            marketingPreference.push('Text Message');
        }

        if (state.values.subscribeNone) {
            marketingPreference.push('None');
        }

        setState((previousState) => {
            const newState = {
                ...previousState,
                values: {
                    ...previousState.values,
                    marketingPreference: marketingPreference,
                }
            }
            if (previousState.allowMarketingPreferenceErrors) {
                if (!atLeastOneCheckboxIsChecked()) {
                    newState.formMessageType = 'error';
                    newState.formMessage = 'Please tick at least one of the marketing preferences.';
                } else {
                    newState.formMessageType = '';
                    newState.formMessage = '';
                }
            } else {
                newState.allowMarketingPreferenceErrors = true;
            }
            return newState;
        });

        validateCheckboxes();
    }, [state.values.subscribeTelephone, state.values.subscribeEmail, state.values.subscribePost, state.values.subscribeSMS, state.values.subscribeNone]);

    // Enable the submit button if all form fields are value and the recaptcha is valid
    useEffect(function() {
        const disableSubmit = Object.keys(state.errors).every((k) => !state.errors[k]) && state.recaptchaValid ? false : true;
        setState((previousState) => {
            return {
                ...previousState,
                disableSubmit: disableSubmit,
            }
        });
    }, [state.errors, state.recaptchaValid]);

    return (
        <>
            <div className={`o-form o-contant-form ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-form__container">
                        <form className="o-form__form" onSubmit={handleSubmit}>
                            <fieldset className="o-form__fieldset">
                                {title && (
                                    <legend className="o-form__legend">{title}</legend>
                                )}
                                {title && (
                                    <SectionHeader
                                        className="o-form__title"
                                        title={title}
                                    />
                                )}
                                {content && (
                                    <Content
                                        className="o-form__content"
                                        content={content}
                                    />
                                )}
                                {state.formMessageType && (
                                    <Message
                                        className={`o-form__message a-message--${state.formMessageType}`}
                                        message={state.formMessage}
                                        type={state.formMessageType}
                                    />
                                )}
                                {(state.formMessageType !== 'success') && (
                                    <div className="o-form__field-group">
                                        {firstNameLabel && (
                                            <InputField
                                                className="o-form__field"
                                                id="contact-form-first-name"
                                                label={firstNameLabel}
                                                name="firstName"
                                                onChange={handleFieldChange}
                                                required
                                                type="text"
                                                value={state.values.firstName}
                                            />
                                        )}
                                        {lastNameLabel && (
                                            <InputField
                                                className="o-form__field"
                                                id="contact-form-last-name"
                                                label={lastNameLabel}
                                                name="lastName"
                                                onChange={handleFieldChange}
                                                required
                                                type="text"
                                                value={state.values.lastName}
                                            />
                                        )}
                                        {emailLabel && (
                                            <InputField
                                                className="o-form__field"
                                                id="contact-form-email"
                                                label={emailLabel}
                                                name="email"
                                                onChange={handleFieldChange}
                                                required
                                                type="email"
                                                value={state.values.email}
                                            />
                                        )}
                                        {phoneLabel && (
                                            <InputField
                                                className="o-form__field"
                                                id="contact-form-phone"
                                                label={phoneLabel}
                                                name="phone"
                                                onChange={handleFieldChange}
                                                required
                                                type="tel"
                                                value={state.values.phone}
                                            />
                                        )}
                                        {postcodeLabel && (
                                            <InputField
                                                className="o-form__field"
                                                id="contact-form-postcode"
                                                label={postcodeLabel}
                                                name="postcode"
                                                onChange={handleFieldChange}
                                                required
                                                type="text"
                                                value={state.values.postcode}
                                            />
                                        )}
                                        {(farmTypeLabel && farmTypeOptionArray) && (
                                            <SelectField
                                                className="o-form__field"
                                                id="contact-form-farm-type"
                                                label={farmTypeLabel}
                                                name="farmType"
                                                onChange={handleFieldChange}
                                                options={farmTypeOptionArray}
                                                required
                                                touched={state.touched.farmType}
                                                value={state.values.farmType}
                                            />
                                        )}
                                        {(landFarmedLabel && landFarmedOptionArray) && (
                                            <div className="m-form-field o-form__field">
                                                <Label
                                                    id="contact-form-land-farmed"
                                                    label={landFarmedLabel}
                                                />
                                                <div className="o-form__field-multiple-inline-fields">
                                                    <Input
                                                        id="contact-form-land-farmed"
                                                        name="landFarmed"
                                                        onChange={handleFieldChange}
                                                        placeholder="100"
                                                        required
                                                        type="number"
                                                        value={state.values.landFarmed}
                                                    />
                                                    <Select
                                                        id="contact-form-land-farmed-unit"
                                                        name="landFarmedUnit"
                                                        onChange={handleFieldChange}
                                                        options={landFarmedOptionArray}
                                                        presetOption={true}
                                                        required
                                                        value={state.values.landFarmedUnit}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {(loanValueLabel && loanValueOptionArray) && (
                                            <SelectField
                                                className="o-form__field"
                                                id="loan-form-loan-value"
                                                label={loanValueLabel}
                                                name="loanValue"
                                                onChange={handleFieldChange}
                                                options={loanValueOptionArray}
                                                required
                                                touched={state.touched.loanValue}
                                                value={state.values.loanValue}
                                            />
                                        )}
                                        {callTimeLabel && (
                                            <InputField
                                                className="o-form__field"
                                                id="contact-form-call-time"
                                                label={callTimeLabel}
                                                name="callTime"
                                                onChange={handleFieldChange}
                                                required
                                                type="time"
                                                value={state.values.callTime}
                                            />
                                        )}
                                        {messageLabel && (
                                            <TextareaField
                                                className="o-form__field"
                                                id="contact-form-message"
                                                label={messageLabel}
                                                name="message"
                                                onChange={handleFieldChange}
                                                value={state.values.message}
                                            />
                                        )}
                                        {(footerContent || subscribeTelephoneLabel || subscribeEmailLabel || subscribePostLabel || subscribeSMSLabel || subscribeNoneLabel) && (
                                            <div className="o-form__subscribe-field">
                                                {footerContent && (
                                                    <div className="o-form__subscribe-content-container">
                                                        <Content
                                                            className="o-form__subscribe-content"
                                                            content={footerContent}
                                                        />
                                                    </div>
                                                )}
                                                {(subscribeTelephoneLabel || subscribeEmailLabel || subscribePostLabel || subscribeSMSLabel || subscribeNoneLabel) && (
                                                    <div className="o-form__subscribe-field-container">
                                                        {subscribeTelephoneLabel && (
                                                            <Checkbox
                                                                checked={state.values.subscribeTelephone}
                                                                id="subscribe-telephone"
                                                                name="subscribeTelephone"
                                                                onChange={handleCheckboxChange}
                                                                text={subscribeTelephoneLabel}
                                                                value={subscribeTelephoneLabel}
                                                            />
                                                        )}
                                                        {subscribeEmailLabel && (
                                                            <Checkbox
                                                                checked={state.values.subscribeEmail}
                                                                id="subscribe-email"
                                                                name="subscribeEmail"
                                                                onChange={handleCheckboxChange}
                                                                text={subscribeEmailLabel}
                                                                value={subscribeEmailLabel}
                                                            />
                                                        )}
                                                        {subscribePostLabel && (
                                                            <Checkbox
                                                                checked={state.values.subscribePost}
                                                                id="subscribe-post"
                                                                name="subscribePost"
                                                                onChange={handleCheckboxChange}
                                                                text={subscribePostLabel}
                                                                value={subscribePostLabel}
                                                            />
                                                        )}
                                                        {subscribeSMSLabel && (
                                                            <Checkbox
                                                                checked={state.values.subscribeSMS}
                                                                id="subscribe-sms"
                                                                name="subscribeSMS"
                                                                onChange={handleCheckboxChange}
                                                                text={subscribeSMSLabel}
                                                                value={subscribeSMSLabel}
                                                            />
                                                        )}
                                                        {subscribeNoneLabel && (
                                                            <Checkbox
                                                                checked={state.values.subscribeNone}
                                                                id="subscribe-none"
                                                                name="subscribeNone"
                                                                onChange={handleNoneCheckboxChange}
                                                                text={subscribeNoneLabel}
                                                                value={subscribeNoneLabel}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div className="o-form__recaptcha-field">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY}
                                                onChange={handleRecaptchaChange}
                                            />
                                        </div>
                                        {submitText && (
                                            <button className="o-form__button a-button a-button--fill a-button--large" disabled={state.disableSubmit} type="submit">{submitText}</button>
                                        )}
                                    </div>
                                )}
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
