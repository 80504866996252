import React from 'react';
import PropTypes from 'prop-types';
import { FeefoReviewCard } from '../../molecules';
import { Button } from '../../atoms';

import './FeefoReviewCards.scss';

const defaultProps = {
    cards: [],
    feefoReviewsIcon: { url: "", alt: "" },
    feefoReviewsTitle: "",
    moreReviewsButtonText: "",
    moreReviewsButtonLink: "",
};

const propTypes = {
    cards: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.shape({
                review_product: PropTypes.string,
                review_stars: PropTypes.string,
                review_text: PropTypes.string,
                review_title: PropTypes.string,
            })
        )
    ),
    feefoReviewsIcon: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    feefoReviewsTitle: PropTypes.string,
    moreReviewsButtonText: PropTypes.string,
    moreReviewsButtonUrl: PropTypes.string,
};

function FeefoReviewCards({
    cards,
    feefoReviewsIcon,
    feefoReviewsTitle,
    moreReviewsButtonText,
    moreReviewsButtonLink,
}) {
    return (
        <div className="o-feefo-review-cards__container">
            <div className="l-grid l-grid--container">
                <div className="o-feefo-review-cards__box">
                    <h2 className="o-feefo-review-cards__title">
                        {feefoReviewsTitle}
                    </h2>
                    <img
                        className="o-feefo-review-cards__feefo-icon"
                        alt={feefoReviewsIcon.alt}
                        src={feefoReviewsIcon.url}
                    />
                    <div className="o-feefo-review-cards__cards-container">
                        {cards.map((card, index) => (
                            <FeefoReviewCard
                                key={index}
                                product={card.review_product.text}
                                stars={card.review_stars}
                                text={card.review_text.text}
                                title={card.review_title.text}
                            />
                        ))}
                    </div>
                    <Button
                        className="a-button--fill"
                        to={{ link_type: "Web", url: moreReviewsButtonLink }}
                    >
                        {moreReviewsButtonText}
                    </Button>
                </div>
            </div>
        </div>
    );
}

FeefoReviewCards.propTypes = propTypes;
FeefoReviewCards.defaultProps = defaultProps;

export default FeefoReviewCards;
