import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { ValueItem } from 'molecules';

import './ValueListing.scss';

const defaultProps = {
    className: '',
    image1: {},
    image2: {},
    listing: [],
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    image1: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    image2: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
    }),
    listing: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

function ValueListing({
    className,
    image1,
    image2,
    listing,
    title,
}) {
    const supportsPicture = typeof window !== 'undefined' ? window.HTMLPictureElement : null;

    return (
        <>
            <div className={`o-value-listing ${className}`}>
                <div className="l-grid l-grid--container">
                    {(image1?.url || image2?.url) && (
                        <div className="o-value-listing__image-container">
                            {image1?.url && (
                                <>
                                    {supportsPicture && (
                                        <Img
                                            alt={(image1?.alt) ? image1.alt : ''}
                                            className="o-value-listing__image"
                                            fluid={{
                                                aspectRatio: image1.dimensions.width / image1.dimensions.height,
                                                src: `${image1?.url}&w=50`,
                                                srcSet: `${image1?.url}&w=375 375w, ${image1?.url}&w=480 480w, ${image1?.url}&w=768 768w, ${image1?.url}&w=960 960w, ${image1?.url}&w=1280 1280w`,
                                                sizes: '(max-width: 1000px) 100vw, 1000px',
                                            }}
                                        />
                                    )}
                                    {!supportsPicture && (
                                        <img alt={(image1?.alt) ? image1.alt : ''} className="o-value-listing__image" src={`${image1?.url}&w=1280`} />
                                    )}
                                </>
                            )}
                            {image2?.url && (
                                <>
                                    {supportsPicture && (
                                        <Img
                                            alt={(image2?.alt) ? image2.alt : ''}
                                            className="o-value-listing__image"
                                            fluid={{
                                                aspectRatio: image2.dimensions.width / image2.dimensions.height,
                                                src: `${image2?.url}&w=50`,
                                                srcSet: `${image2?.url}&w=375 375w, ${image2?.url}&w=480 480w, ${image2?.url}&w=768 768w, ${image2?.url}&w=960 960w, ${image2?.url}&w=1280 1280w`,
                                                sizes: '(max-width: 1000px) 100vw, 1000px',
                                            }}
                                        />
                                    )}
                                    {!supportsPicture && (
                                        <img alt={(image2?.alt) ? image2.alt : ''} className="o-value-listing__image" src={`${image2?.url}&w=1280`} />
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    {title && (
                        <div className="o-value-listing__title-container">
                            <h2 className="o-value-listing__title">{title}</h2>
                        </div>
                    )}
                    {listing && (
                        <div className="o-value-listing__item-container">
                            {listing.map(({ value_listing_value_content, value_listing_value_title }, index) => (
                                <ValueItem
                                    className="o-value-listing__item"
                                    content={value_listing_value_content?.raw}
                                    key={`{${index}${Math.random()}}`}
                                    title={value_listing_value_title?.text}
                                />
                            ))}
                        </div>
                    )}
                    <div className="o-value-listing__content-background" />
                    <div className="o-value-listing__background" />
                </div>
            </div>
        </>
    );
}

ValueListing.propTypes = propTypes;
ValueListing.defaultProps = defaultProps;

export default ValueListing;
