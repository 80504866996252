import React from 'react';
import PropTypes from 'prop-types';
import { Label, Textarea } from 'atoms';

import './TextareaField.scss';

const defaultProps = {
    className: '',
    disabled: false,
    id: '',
    label: '',
    name: '',
    onChange: () => {},
    placeholder: '',
    required: false,
    rows: 4,
};

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rows: PropTypes.number,
};

function TextareaField({
    className,
    disabled,
    id,
    label,
    name,
    onChange,
    placeholder,
    required,
    rows,
}) {
    return (
        <>
            <div className={`m-form-field ${className}`}>
                <Label
                    id={id}
                    label={label}
                    required={required}
                />
                <Textarea
                    disabled={disabled}
                    id={id}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    required={required}
                    rows={rows}
                />
            </div>
        </>
    );
}

TextareaField.propTypes = propTypes;
TextareaField.defaultProps = defaultProps;

export default TextareaField;
